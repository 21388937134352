import React, { useState, useEffect } from 'react';
import LoadedBagsIcon from '../../Assets/SVG/DashBoard/LoadedBags.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RiArrowDropDownLine } from "react-icons/ri";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { fetchFactoryNames, setLoadedBagsfilterPeriod, fetchTotalLoadedBags, setStateLocation } from '../../feature/Dashboard/dashboardSlice';

function LoadBags() {

  const formatString = (str) => {
    return str.toLowerCase().replace(/ /g, '_');
  };

  const { locationSelected } = useSelector((state) => state.navbar);
  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);
  const totalLoadedBags = useSelector((state) => state.dashboard.totalLoadedBags);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFactoryNames({ 'state': locationSelected }));
  }, [dispatch, locationSelected]);

  const raw_factories = useSelector((state) => state.dashboard.factoryNames);
  const factories = ['All Factories', ...raw_factories]; // Add "All Factories" to the list

  const [selectedTimeRange, setSelectedTimeRange] = useState('Till Today');
  const [loadedBagsCount, setLoadedBagsCount] = useState(0);
  const [loadedBagsChangePercentage, setLoadedBagsChangePercentage] = useState(0);
  const [selectedFactory, setSelectedFactory] = useState("All Factories");
  const timeRanges = ['Till Today', 'Last 3 Months', 'Last 6 Months', 'Last 9 Months', 'Last 1 Year'];

  useEffect(() => {
    dispatch(setLoadedBagsfilterPeriod(formatString(selectedTimeRange)));
  }, [selectedTimeRange, dispatch]);

  useEffect(() => {
    if (selectedFactory !== 'All Factories') {
      dispatch(fetchTotalLoadedBags({ "factory_name": selectedFactory, "filter_period": formatString(selectedTimeRange) }));
    } else {
      dispatch(fetchTotalLoadedBags({ "filter_period": formatString(selectedTimeRange) })); // Call API without factory name
    }
  }, [selectedFactory, selectedTimeRange, dispatch]);

  useEffect(() => {
    if (totalLoadedBags) {
      if (totalLoadedBags.total_intact_bag_count) {
        setLoadedBagsCount(totalLoadedBags.total_intact_bag_count);
      }
      if (totalLoadedBags.change_percentage) {
        setLoadedBagsChangePercentage(totalLoadedBags.change_percentage);
      }
    }
  }, [totalLoadedBags]);

  return (
    <div className='p-5 flex flex-col gap-y-2 bg-white rounded-lg flex-1'>
      <div className='flex justify-between w-full'>
        <div><img src={LoadedBagsIcon} alt="Loaded-Bags" /></div>
        <div>
          <Menu as="div" className="z-30 relative px-2 mb-2 mt-2">
            <MenuButton className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span>{selectedFactory}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </MenuButton>
            <MenuItems className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
              {factories.map((factory, index) => (
                <MenuItem key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 text-xs text-[#126568] cursor-pointer`}
                      onClick={() => setSelectedFactory(factory)}
                    >
                      {factory}
                    </div>
                  )}
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </div>
      </div>

      <div className='flex justify-between w-full'>
        <div className='text-sm'>Total Loaded Bags</div>
        <div>
          <Menu as="div" className="z-20 relative bg-[#E5F7F8] px-2 border border-transparent rounded text-[#126568] mb-2">
            <MenuButton className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span className="text-sm">{selectedTimeRange}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </MenuButton>
            <Menu.Items className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
              {timeRanges.map((range, index) => (
                <MenuItem key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 text-xs text-[#126568] cursor-pointer`}
                      onClick={() => setSelectedTimeRange(range)}
                    >
                      {range}
                    </div>
                  )}
                </MenuItem>
              ))}
            </Menu.Items>
          </Menu>
        </div>
      </div>

      <div className='flex justify-between w-full'>
        <div className='font-bold text-[4vh] text-[#126568]'>{loadedBagsCount}</div>
        <div className='flex flex-col'>
          <div
            className={`py-1 flex justify-end text-sm ${
              loadedBagsChangePercentage > 0
                ? 'text-green-800'
                : loadedBagsChangePercentage < 0
                ? 'text-red-600'
                : 'text-gray-600'
            }`}
          >
            <span
              className={`px-2 border-transparent rounded ${
                loadedBagsChangePercentage > 0
                  ? 'bg-emerald-300'
                  : loadedBagsChangePercentage < 0
                  ? 'bg-red-100'
                  : 'bg-gray-200'
              }`}
            >
              {loadedBagsChangePercentage.toFixed(2)}%
            </span>
          </div>
          <div className='text-xs mb-2'>As compared to last week.</div>
        </div>
      </div>
    </div>
  );
}

export default LoadBags;
