import React, { useState } from 'react';
import LoadBags from '../container/Dashboard/LoadBags';
import UnloadBags from '../container/Dashboard/UnloadBags';
import TotalStocks from '../container/Dashboard/TotalStocks';
import LoadUnLoadStatsHead from '../container/Dashboard/LoadUnLoadStatsHead';
import TotalTrucksStatsHead from '../container/Dashboard/TotalTrucksStatsHead';
import ArrivalDepartureHead from '../container/Dashboard/ArrivalDepartureHead';
import HandlingRateHead from '../container/Dashboard/HandlingRateHead';
import LocationWise from '../container/Dashboard/LocationWise';

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="bg-[#F6F6F8]  border-gray-200 rounded-md h-[85vh]  flex flex-col">
      <div className="flex-1 flex flex-col gap-5 pt-1 pb-1 pl-2 pr-2 overflow-y-scroll overflow-x-hidden h-[85vh]">
        <div className="flex flex-col gap-5">
          <div className="flex flex-wrap justify-between gap-5">
            <LoadBags />
            <UnloadBags />
            {/* <TotalStocks /> */}
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex justify-between gap-5">
              <div className="flex-1" style={{ flex: '0 0 66.3%' }}>
                <LoadUnLoadStatsHead />
              </div>
              <div className="flex-1" style={{ flex: '0 0 32.5%' }}>
                <TotalTrucksStatsHead />
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="flex-1" style={{ flex: '0 0 55%' }}>
                <ArrivalDepartureHead />
              </div>
              <div className="flex-1" style={{ flex: '0 0 45%' }}>
                <HandlingRateHead />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`flex ${isModalOpen ? 'blur-md' : ''} transition-all duration-300 ease-in-out`}>
        <LocationWise isOpen={isModalOpen} closeModal={closeModal} />
      </div>
    </div>
  );
}

export default Dashboard;
