// Table.js
import React from 'react';
import NoData from './NoData';

const Table = ({ columns, data }) => {
  const isDataEmpty = data.length === 0;

  return (
    <div className="overflow-x-auto my-4 min-h-[50vh] max-h-[50vh]">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                className={`${column.headerClassName} px-6 py-3 text-left text-xs font-medium uppercase tracking-wider`}
              >
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 overflow-y-auto">
          {isDataEmpty ? (
            <tr>
              <td colSpan={columns.length} className="px-6 py-4 text-center text-sm text-gray-500">
                <NoData />
              </td>
            </tr>
          ) : (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-custom-light' : 'bg-white'}>
                {columns.map((column) => (
                  <td
                    key={column.accessor}
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {row[column.accessor]}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
