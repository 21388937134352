import React, { useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Description } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

const FilterModal = ({ isOpen, onClose }) => {
    const [checkedItems, setCheckedItems] = useState({
        item1: false,
        item2: false,
        item3: false,
        item4: false,
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckedItems((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const onReset = () => {
        setCheckedItems({
            item1: false,
            item2: false,
            item3: false,
            item4: false,
        })
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <DialogPanel className="bg-white rounded-lg w-[26vw] max-w-xl mx-auto p-6 relative">
                    <button
                        type="button"
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                    >
                        <XMarkIcon className="w-6 h-6 text-[#F23B3B]" />
                    </button>
                    <DialogTitle className="text-2xl text-gray-900">Filters</DialogTitle>
                    <Description className="mt-2 text-m text-gray-600">
                        Select Timeline
                    </Description>
                    <div className="my-6 flex flex-wrap w-[22vw] justify-between items-centerm gap-4">
                        <label className="flex items-center space-x-2">
                            <span className="text-xl text-[#126568] font-bold">Past 1 Month</span>
                            <input
                                type="checkbox"
                                name="item1"
                                checked={checkedItems.item1}
                                onChange={handleCheckboxChange}
                                className="h-5 w-5 text-green-600 focus:ring-green-500 border-green-300 rounded"
                            />
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <span className="text-xl text-[#126568] font-bold">Past 3 Month</span>
                            <input
                                type="checkbox"
                                name="item2"
                                checked={checkedItems.item2}
                                onChange={handleCheckboxChange}
                                className="h-5 w-5 text-green-600 focus:ring-green-500 border-green-300 rounded"
                            />
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <span className="text-xl text-[#126568] font-bold">Past 9 Month</span>
                            <input
                                type="checkbox"
                                name="item3"
                                checked={checkedItems.item3}
                                onChange={handleCheckboxChange}
                                className="h-5 w-5 text-green-600 focus:ring-green-500 border-green-300 rounded"
                            />
                        </label>
                        <label className="flex items-center space-x-2 mt-2">
                            <span className="text-xl text-[#126568] font-bold">Past 1 Year</span>
                            <input
                                type="checkbox"
                                name="item4"
                                checked={checkedItems.item4}
                                onChange={handleCheckboxChange}
                                className="h-5 w-5 text-green-600 focus:ring-green-500 border-green-300 rounded"
                            />
                        </label>
                    </div>
                    <div className="mt-10 flex justify-end gap-2">
                        <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-[#242325] text-xl font-semibold cursor-pointer"
                            onClick={onReset}
                        >
                            Reset
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-white bg-teal-600 border border-transparent rounded-md shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 cursor-point"
                            onClick={onClose}
                        >
                            Apply
                        </button>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
};


export default FilterModal;
