import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getAuthToken = () => localStorage.getItem("Authorization");

// Async thunks for various API calls
export const fetchLocations = createAsyncThunk(
  "location/fetchLocations",
  async (_, { getState }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/state_list/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCompanies = createAsyncThunk(
  "location/fetchCompanies",
  async (location, { getState }) => {
    try {
      const { selectedLocation } = getState().location;
      console.log(location);
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/cnf_list/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params:  { state: location["location"] },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAddressOwnerDetails = createAsyncThunk(
  "location/fetchAddressOwnerDetails",
  async ({ cnf, state }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/address_owner_details/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchHandlingRate = createAsyncThunk(
  "location/fetchHandlingRate",
  async ({ cnf, state }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/handling_rate/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAddressCompanyDetails = createAsyncThunk(
  "location/fetchAddressCompanyDetails",
  async ({ cnf, state }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/cnf_address/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTruckArrivalAnalysis = createAsyncThunk(
  "location/fetchTruckArrivalAnalysis",
  async ({ cnf, state, duration }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/truck_arrival_analysis/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state, duration },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// New async thunk for unloading analysis
export const fetchUnloadingAnalysis = createAsyncThunk(
  "location/fetchUnloadingAnalysis",
  async ({ cnf, state, duration }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/unloading_analysis/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state, duration },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLoadedUnloadedData = createAsyncThunk(
  "location/fetchLoadedUnloadedData",
  async ({ cnf, state, duration }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/loading_unloading_by_trucks/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state, duration },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchHandlingRateByTruck = createAsyncThunk(
  "location/fetchHandlingRateByTruck",
  async ({ truck, cnf, state, duration }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/handling_rate_by_truck/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { truck, cnf, state, duration },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// New async thunk for fetching truck list
export const fetchTruckList = createAsyncThunk(
  "location/fetchTruckList",
  async ({ cnf, state }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/trucks_list/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLoadingUnloadingCounts = createAsyncThunk(
  "location/fetchLoadingUnloadingCounts",
  async ({ cnf, state, duration }) => {
    try {
      const response = await axios.get(
        "https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/loading_unloading_counts/",
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf, state, duration },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const LocationSlice = createSlice({
  name: "location",
  initialState: {
    locations: [],
    companies: [],
    selectedLocation: "",
    selectedCompany: "",
    addressOwnerDetails: null,
    handlingRate: null,
    addressCompanyDetails: null,
    truckArrivalAnalysis: null,
    unloadingAnalysis: null,
    loadedUnloadedData: [], // State for loaded/unloaded data
    truckList: [], // State for truck list
    loadingUnloadingCounts: null, // New state for loading/unloading counts
    duration: "this_month",
    status: "idle",
    error: null,
    fetchAddressOwner: false,
    fetchHandlingRate: false,
    handlingRateByTruck: null, // New state for handling rate by truck
  },
  reducers: {
    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
      state.fetchAddressOwner = true;
      state.fetchHandlingRate = true;
    },
    setSelectedCompany(state, action) {
      state.selectedCompany = action.payload;
      // state.fetchAddressOwner = true;
      // state.fetchHandlingRate = true;
    },
    setDuration(state, action) {
      const validDurations = ["this_week", "this_month"];
      if (validDurations.includes(action.payload)) {
        state.duration = action.payload;
      } else {
        state.error = "Invalid duration selected";
      }
    },
    resetFilters(state) {
      state.selectedLocation = "";
      state.selectedCompany = "";
      state.addressOwnerDetails = null;
      state.handlingRate = null;
      state.truckArrivalAnalysis = null;
      state.unloadingAnalysis = null;
      state.loadedUnloadedData = []; // Reset loaded/unloaded data
      state.truckList = []; // Reset truck list
      state.loadingUnloadingCounts = null; // Reset loading/unloading counts
      state.duration = "this_month";
      state.status = "idle";
      state.error = null;
      state.fetchAddressOwner = false;
      state.fetchHandlingRate = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.locations = action.payload;
        if (!state.selectedLocation && action.payload.length > 0) {
          state.selectedLocation = action.payload[0];
        }
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCompanies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companies = action.payload;
        state.selectedCompany = action.payload[0];
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAddressOwnerDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAddressOwnerDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addressOwnerDetails = action.payload;
      })
      .addCase(fetchAddressOwnerDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchHandlingRate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHandlingRate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.handlingRate = action.payload;
      })
      .addCase(fetchHandlingRate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAddressCompanyDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAddressCompanyDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addressCompanyDetails = action.payload;
      })
      .addCase(fetchAddressCompanyDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTruckArrivalAnalysis.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTruckArrivalAnalysis.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.truckArrivalAnalysis = action.payload;
      })
      .addCase(fetchTruckArrivalAnalysis.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUnloadingAnalysis.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUnloadingAnalysis.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.unloadingAnalysis = action.payload;
      })
      .addCase(fetchUnloadingAnalysis.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLoadedUnloadedData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLoadedUnloadedData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loadedUnloadedData = action.payload;
      })
      .addCase(fetchLoadedUnloadedData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchHandlingRateByTruck.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHandlingRateByTruck.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.handlingRateByTruck = action.payload;
      })
      .addCase(fetchHandlingRateByTruck.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTruckList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTruckList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.truckList = action.payload;
      })
      .addCase(fetchTruckList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLoadingUnloadingCounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLoadingUnloadingCounts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loadingUnloadingCounts = action.payload;
      })
      .addCase(fetchLoadingUnloadingCounts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setSelectedLocation,
  setSelectedCompany,
  setDuration,
  resetFilters,
} = LocationSlice.actions;

export default LocationSlice.reducer;
