import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from '../container/Sidebar/Sidebar';
import Navbar from '../component/NavBar';
import Dashboard from '../pages/Dashboard';
import LocationPage from '../pages/LocationPage';
import StockPage from '../pages/StockPage';
import TruckInfoPage from '../pages/TruckInfoPage';
import LocationWise from '../container/Dashboard/LocationWise'; // Ensure import is correct

const Layout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  
  const openModal = () => {
    console.log("Opening modal");
    setIsModalOpen(true);
  };
  const closeModal = () => {
    console.log("Closing modal");
    setIsModalOpen(false);
  };

  const routeDetails = {
    '/home': { title: 'Dashboard', subTitle: 'Select the location and time to update the dashboard elements.' },
    '/location': { title: 'Location wise', subTitle: 'Select the location to analyze the C&F Company in the particular region.' },
    '/stock': { title: 'Stock Analysis', subTitle: 'Select the location to analyze the stocks available in the particular region.' },
    '/truckinfo': { title: 'Truck Info', subTitle: 'Select the C&F Company to view the details of the trucks.' }
  };

  const { title, subTitle } = routeDetails[location.pathname] || { title: '', subTitle: '' };

  const data = [
    { path: "/home", element: <Dashboard /> },
    { path: "/location", element: <LocationPage /> },
    { path: "/stock", element: <StockPage /> },
    { path: "/truckinfo", element: <TruckInfoPage /> }
  ];

  return (
    <div className="flex bg-[#F6F6F8] border border-gray-200 rounded-md pl-2 pr-2 ">
      <Sidebar className="static" />
      <div className="flex-1 flex flex-col p-1">
        <Navbar
          title={title}
          subTitle={subTitle}
          className="fixed w-full pl-2"
          openModal={openModal}
        />
        <div className="bg-[#F6F6F8] pt-1">
          <Routes>
            {data.map((item, key) => (
              <Route path={item.path} element={item.element} key={key} />
            ))}
          </Routes>
        </div>
        {isModalOpen && (
          <div className="">
            <LocationWise isOpen={isModalOpen} closeModal={closeModal} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Layout;
