import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop
} from 'recharts';

// Custom Tooltip component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="bg-white rounded-lg p-2 shadow-xl w-50 text-sm">
        <div className="flex gap-2">
          {payload.map((item, index) => (
            <div key={index} className="mr-2">
             <p className="m-0 text-xs text-teal-500 font-600">{data.name}</p>
              <p className="m-0 text-black text-base font-bold opacity-0.5 font-12">{`${item.value}% `}</p>
              <p className={`m-0 text-sm font-bold text-teal-500 ${item.fill}`}>{item.name}</p>
            </div>
          ))}
        </div>
        
        
      </div>
    );
  }
  return null;
};

const TruckRateAnalysisGraph = ({ data }) => {
    return (
        <div className="w-full h-96 p-4 relative">
          <ResponsiveContainer width="100%" height="90%">
            <BarChart
              data={data}
              margin={{
                top: 10, // Adjust to provide space for the text above
                right: 20,
                left: 5,
                
              }}
            >
              <defs>
                <linearGradient id="gradientColor" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="0%" stopColor="#00ADB5" stopOpacity={1} />
                  <stop offset="100%" stopColor="#004C4F" stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" vertical={false} className="bg-gray-100" />
              <XAxis dataKey="name" tick={{ fill: '#00ADB5' }} className="text-xs font-bold" />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#00ADB5' }}
                className="text-xs font-bold"
                label={{ value: 'Rate', angle: -90, position: 'insideLeft', fill: 'gray' }}
                ticks={[0, 25, 50, 75, 100]}
                tickFormatter={(value) => `${value}%`}
              />
              <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
              <Bar dataKey="HandlingRate" barSize={20} fill="url(#gradientColor)" className="no-hover" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    };

export default TruckRateAnalysisGraph