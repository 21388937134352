import { Button, Dialog, DialogPanel, DialogTitle, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import Select from 'react-select'
import HandlingRateAnalyzeGraph from '../HandleRateAnalyzeGraph'
import { IoCloseOutline } from "react-icons/io5";
import { fetchCnFNames } from "../../../feature/Dashboard/dashboardSlice";
import { useDispatch, useSelector } from 'react-redux';

export function HandlingRateModal({ isOpen, setIsOpen }) {
  const [query, setQuery] = useState('')

  const formatString = (str) => {
    return str.toLowerCase().replace(/ /g, '_');
  };

  const {locationSelected} = useSelector((state) => state.navbar);

  const getRandomLightColors = () => {
    const colors = [
      '#BBE1FA', '#DAF7A6', '#B0E57C', '#D1F2A5', '#A0E7E5', '#A7F3D0', '#B3E5FC', '#B2DFDB',
      '#C8E6C9', '#FFEBEE', '#FFCDD2', '#F0F4C3', '#E1BEE7', '#FFCCBC', '#D7CCC8', '#F5E1DA'
    ];
    return colors;
  };

  const formatOptions = (options, colors) => {
    return options.map((option, index) => ({
      value: formatString(option),
      label: option,
      color: colors[index % colors.length]  // Ensuring unique color
    }));
  };

  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);

  const state_name = "Haryana"

  const totalUnloadedBags = useSelector((state) => state.dashboard.totalUnloadedBags)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCnFNames({ 'state': locationSelected }));
  }, [dispatch, locationSelected]);

  const raw_cnf = useSelector((state) => state.dashboard.CnFNames)
  const companies = raw_cnf;

  const colors = getRandomLightColors();
  const formattedOptions = formatOptions(companies, colors);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid #00ADB5",
      borderRadius: "0px",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? state.data.color : provided.backgroundColor,
      color: state.isSelected ? 'white' : 'black',
      ':hover': {
        backgroundColor: state.data.color,
        color: 'white',
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.color,
      borderRadius: "15px"
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  const [selectedOptions, setSelectedOptions] = useState([])

  const handleChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
  }

  return (
    <>
      <Dialog open={isOpen} as="div" className="relative z-50 focus:outline-none" onClose={close}>
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="fixed inset-0 bg-black/30 backdrop-blur-sm z-40" aria-hidden="true"></div>
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full bg-white rounded-xl p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 max-w-[60rem] z-50"
            >
              <DialogTitle as="h3" className="text-base/7 font-medium text-black flex justify-between">
                <h3 className='text-[24px] font-[400] leading-[32.74px]'>Analyze Handling Rate </h3>
                <div className='relative'>
                  <IoCloseOutline size={22} onClick={close} style={{ color: "red", position: "absolute", bottom: "1rem", right: "-1rem", cursor: "pointer", height: "2rem" }} />
                </div>
              </DialogTitle>

              <label className='text-[#B4B4B4] text-[13px]'>C&F Company</label>
              <Select
                className='relative z-40'
                options={formattedOptions}
                value={selectedOptions}
                onChange={handleChange}
                isMulti={true}
                styles={customStyles}
              />

              <HandlingRateAnalyzeGraph selectedOptions={selectedOptions} />

            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
