import "./App.css";
import "./index.css"
import { Routes, Route } from "react-router";
import LoginPage from "./pages/LoginPage/LoginPage";
import ForgotPassword from "./container/Credentials/ForgotPassword";
import Register from "./container/Credentials/Register";
import LocationPage from "./pages/LocationPage";
import Dashboard from "./pages/Dashboard";
import TruckInfoPage from "./pages/TruckInfoPage";
import Stock from "./pages/StockPage"
import Layout from "./component/Layout";



function App() {
  return (
    <div className="App h-[100vh] overflow-hidden">
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/forgot_password' element={<ForgotPassword />} />
        <Route path='/register' element={<Register />} />
        <Route path="/*" element={<Layout/>}/>

      </Routes>
    </div>
  );
}


export default App;
