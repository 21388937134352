import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { RiArrowDropDownLine } from "react-icons/ri";
import LoadUnLoadGraph from './LoadUnLoadGraph';
import { fetchCnFNames, setloadingUnloadingAnalysisfilterPeriod, fetchLoadingUnloadingAnalysis } from '../../feature/Dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  return `${month} ${day}`;
};

const dataFormatter = (data) => {
  if (!data || !Array.isArray(data)) return [];

  const dateMap = {};

  data.forEach(({ period, total_loaded_bags, total_unloaded_bags }) => {
    dateMap[period] = {
      Loaded: total_loaded_bags || 0,
      Unloaded: total_unloaded_bags || 0,
    };
  });

  const allDates = Object.keys(dateMap);

  const finalData = allDates.map(date => ({
    name: formatDate(date),
    Loaded: dateMap[date].Loaded,
    Unloaded: dateMap[date].Unloaded,
  }));

  return finalData;
};

const LoadUnLoadStatsHead = () => {
  const [activeFilter, setActiveFilter] = useState('7 days');
  const { locationSelected } = useSelector((state) => state.navbar);

  const filters = ['7 days', '1 month'];

  const formatString = (str) => str.toLowerCase().replace(/ /g, '_');

  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);

  const dispatch = useDispatch();

  const raw_cnf = useSelector((state) => state.dashboard.CnFNames);
  const companies = ['All C&F', ...raw_cnf];

  const [selectedCompany, setSelectedCompany] = useState('All C&F');

  useEffect(() => {
    dispatch(fetchCnFNames({ state: locationSelected }));
    setSelectedCompany('All C&F');
    dispatch(fetchLoadingUnloadingAnalysis({ cnf_name: '', period: formatString(activeFilter) }));
  }, [dispatch, locationSelected]);

  useEffect(() => {
    dispatch(setloadingUnloadingAnalysisfilterPeriod(formatString(activeFilter)));
  }, [activeFilter, dispatch]);

  useEffect(() => {
    let cnf_name = selectedCompany === 'All C&F' ? '' : selectedCompany;
    dispatch(fetchLoadingUnloadingAnalysis({ cnf_name, period: formatString(activeFilter) }));
  }, [selectedCompany, activeFilter, dispatch]);

  const initialData = [];
  const loadingUnloadingAnalysis_data = useSelector((state) => state.dashboard.loadingUnloadingAnalysis.data);
  const [data, setData] = useState(initialData);
  const [yDomain, setYDomain] = useState([0, 50]);

  useEffect(() => {
    if (loadingUnloadingAnalysis_data) {
      const formattedData = dataFormatter(loadingUnloadingAnalysis_data);
      setData(formattedData);
      if (formattedData.length > 0) {
        const allCounts = formattedData.flatMap(item => [item.Loaded, item.Unloaded]);
        const maxCount = Math.max(...allCounts);
        setYDomain([0, maxCount + 10]);
      }
    }
  }, [loadingUnloadingAnalysis_data]);

  return (
    <div className="p-5 bg-white rounded-lg mx-auto flex flex-col h-[55vh]">
      <div className="flex items-center justify-between w-full mb-4">
        <h1 className="text-[1.75vh] font-semibold text-[#126568]">Loading/Unloading Analysis</h1>
        <div className="flex items-center space-x-4">
          <Menu as="div" className="z-10 relative">
            <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span className="text-sm">{selectedCompany}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </Menu.Button>
            <Menu.Items className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
              {companies.map((company, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 cursor-pointer text-xs text-[#126568]`}
                      onClick={() => setSelectedCompany(company)}
                    >
                      {company}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
          <div className="bg-[#F4F6F8] rounded-md px-2 py-1 flex space-x-2">
            {filters.map((filter) => (
              <div
                key={filter}
                onClick={() => setActiveFilter(filter)}
                className={`px-2 py-2 rounded-md cursor-pointer text-[1.5vh] ${
                  activeFilter === filter
                    ? 'bg-[#126568] text-white'
                    : 'bg-[#F4F6F8] text-[#126568]'
                } hover:bg-[#126568] hover:text-white transition-colors`}
              >
                {filter}
              </div>
            ))}
          </div>
        </div>
      </div>
      <LoadUnLoadGraph data={data} yDomain={yDomain} />
    </div>
  );
};

export default LoadUnLoadStatsHead;
