import React from 'react';

const HandlingRate = ({title, value, unit}) => {
  return (
    <div className="w-[100%] p-5 bg-white rounded-md h-[18vh]">
      <h1 className="text-[1.5vh] font-bold text-black mb-1">{title}</h1>
      <div className="flex items-baseline">
        <div className="text-[4vh] font-bold text-[#126568] mr-2">{value}</div>
        <span className="text-[1.5vh] text-[#B4B4B4]">{unit}</span>
      </div>
    </div>
  );
}

export default HandlingRate;