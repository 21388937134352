import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';

// Data with additional entries
const initialData = [];

const CustomizedAxisTick = ({ x, y, stroke, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={5} textAnchor="middle" fill="#126568" className="font-bold text-xs font-[0.5vh]" >
      {payload.value}
    </text>
  </g>
);

const CustomLegend = () => (
  // <div className="absolute top-0 right-0 ml-4 p-2">
  <div 
  // className="absolute bottom-0 right-0 ml-4" 
  className=''
  style={{position: "absolute", bottom: "-80px", marginLeft: "150px"}}
  >
    <div className="flex items-end justify-end">
      <div className="flex items-center mr-4">
        <span className="bg-teal-500 w-3 h-3 inline-block mr-1"></span>
        <span>10-25 Tons Trucks</span>
      </div>
      <div className="flex items-center mr-4">
        <span className="bg-black w-3 h-3 inline-block mr-1"></span>
        <span>Over 25 Tons Trucks</span>
      </div>
    </div>
  </div>
);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-white rounded-lg p-2 shadow-xl w-60 text-[1.75vh]">
        <div className="flex gap-2">
          {payload.map((item, index) => (
            <div key={index} className="mr-2">
              <p className={`m-0 text-[1.5vh] font-bold text-teal-500 ${item.fill}`}>{item.name}</p>
              <p className="m-1 text-gray-500 text-base font-500 opacity-0.5 text-[1.25vh]">{`${item.value} Trucks`}</p>
            </div>
          ))}
        </div>
        <p className="ml-9 text-[1.75vh] text-teal-500 font-normal">{data.name}</p>
      </div>
    );
  }
  return null;
};

const CustomDot = ({ cx, cy, stroke }) => {
  const [active, setActive] = useState(false);

  return (
    <g>
      {active && (
        <circle cx={cx} cy={cy} r={4} stroke={stroke} strokeWidth={2} fill={stroke} />
      )}
    </g>
  );
};

const dataFormatter = (data) => {
  console.log(data)
  if (data) {
    return data.map(item => {
      const date = new Date(item.date);
      const day = String(date.getDate()).padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'short' });

      return {
        name: `${month} ${day}`,
        Arrived: item.arrival_trucks_less_than_25_ton,
        Departure: item.arrival_trucks_more_than_25_ton,
      };
    });
  }
};

const ArrivalDepartureGraph = () => {
  const dispatch = useDispatch();
  const arrivalDepartureAnalysis_data = useSelector((state) => state.dashboard.arrivalDepartureAnalysis);
  const [data, setData] = useState(initialData);
  const [totalArrivalCount, setTotalArrivalCount] = useState(0);
  const [totalDepartureCount, setTotalDepartureCount] = useState(0);
  const [yDomain, setYDomain] = useState([0, 10]);
  const [yTicks, setYTicks] = useState([]);

  useEffect(() => {
    if (arrivalDepartureAnalysis_data) {
      setTotalArrivalCount(arrivalDepartureAnalysis_data.total_arrival_trucks_less_than_25_ton);
      setTotalDepartureCount(arrivalDepartureAnalysis_data.total_arrival_trucks_more_than_25_ton);
      const formattedData = dataFormatter(arrivalDepartureAnalysis_data.count_by_day);
      setData(formattedData);

      if (formattedData) {
        const allCounts = formattedData.flatMap(item => [item.Arrived, item.Departure]);

        // Calculate the maximum and minimum values from the counts
        const maxCount = Math.max(...allCounts);
        const minCount = Math.min(...allCounts);

        // Calculate the step size to ensure ticks are equidistant and visible
        const intervalCount = 5;
        const step = Math.ceil((maxCount - minCount) / (intervalCount - 1));

        // Generate tick values ensuring that ticks cover the full range
        const ticks = Array.from({ length: intervalCount }, (_, i) => minCount + i * step);

        // Set the Y-axis domain with some buffer
        setYDomain([Math.min(0, minCount - step), Math.max(maxCount + step, 10)]);
        setYTicks(ticks);
      }
    }
  }, [arrivalDepartureAnalysis_data]);

  return (
    <div className="relative w-[100%] h-[65vh] mb-[5vh]">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center left-4">
          <div className="ml-4">
            <h3 className="text-[1.5vh] font-semibold text-gray-500">10-25 Tons Trucks Arrived</h3>
            <p className="text-[2vh] font-bold text-teal-600">{totalArrivalCount}</p>
          </div>
          <div className="ml-6">
            <h3 className="text-[1.5vh] font-semibold text-gray-500">Over 25 Tons Trucks Arrived</h3>
            <p className="text-[2vh] font-bold text-teal-600">{totalDepartureCount}</p>
          </div>
        </div>
        <CustomLegend />
      </div>
      <div className="absolute top-15 left-0 w-full h-full">
        <div className="absolute left-9 text-[#B4B4B4] font-600 transform -translate-y-4 mb-20">
          <h3 className="text-[1.25vh]">Trucks</h3>
        </div>
        <ResponsiveContainer width="100%">
          <LineChart
            width={'100%'}
            data={data}
            margin={{
              right: 0,
              bottom: 10,
              left: -10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} className="bg-gray-100" />
            <XAxis dataKey="name" height={35} tick={<CustomizedAxisTick />} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', width: '500px' }} tickMargin={10} axisLine={false} />
            <YAxis
              // domain={yDomain}
              tick={<CustomizedAxisTick />}
              tickMargin={20}
              interval={0}
              axisLine={false}
              ticks={yTicks}
            />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
            <Line dataKey="Arrived" stroke="#00ADB5" strokeWidth={2} dot={<CustomDot />} />
            <Line dataKey="Departure" stroke="#242325" strokeWidth={2} dot={<CustomDot />} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ArrivalDepartureGraph;
