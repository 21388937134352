import React, { useState, useEffect } from 'react';
import UnloadedBagsIcon from '../../Assets/SVG/DashBoard/UnloadedBags.svg';
import { RiArrowDropDownLine } from "react-icons/ri";
import { Menu } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCnFNames, setUnloadedBagsfilterPeriod, fetchTotalUnloadedBags } from '../../feature/Dashboard/dashboardSlice';

function UnloadBags() {
  const { locationSelected } = useSelector((state) => state.navbar);

  const formatString = (str) => {
    return str.toLowerCase().replace(/ /g, '_');
  };

  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);
  const totalUnloadedBags = useSelector((state) => state.dashboard.totalUnloadedBags);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCnFNames({ 'state': locationSelected }));
  }, [dispatch, locationSelected]);

  const raw_cnf = useSelector((state) => state.dashboard.CnFNames);
  const companies = ['All C&F', ...raw_cnf]; // Add "All C&F" as the first item

  const [selectedCompany, setSelectedCompany] = useState("All C&F");
  const [selectedTimeRange, setSelectedTimeRange] = useState('Till Today');
  const timeRanges = ['Till Today', 'Last 3 Months', 'Last 6 Months', 'Last 9 Months', 'Last 1 Year'];

  const [unloadedBagsCount, setUnloadedBagsCount] = useState(0);
  const [unloadedBagsChangePercentage, setUnloadedBagsChangePercentage] = useState(0);

  useEffect(() => {
    dispatch(setUnloadedBagsfilterPeriod(formatString(selectedTimeRange)));
  }, [selectedTimeRange, dispatch]);

  useEffect(() => {
    if (selectedCompany !== 'All C&F') {
      dispatch(fetchTotalUnloadedBags({ "cnf_name": selectedCompany, "filter_period": formatString(selectedTimeRange) }));
    } else {
      dispatch(fetchTotalUnloadedBags({ "filter_period": formatString(selectedTimeRange) }));
    }
  }, [selectedCompany, selectedTimeRange, dispatch]);

  useEffect(() => {
    if (totalUnloadedBags) {
      if (totalUnloadedBags.total_intact_bag_count) {
        setUnloadedBagsCount(totalUnloadedBags.total_intact_bag_count);
      }
      if (totalUnloadedBags.change_percentage) {
        setUnloadedBagsChangePercentage(totalUnloadedBags.change_percentage);
      }
    }
  }, [totalUnloadedBags]);

  return (
    <div className='p-5 flex flex-col gap-y-2 bg-white flex-1 rounded-lg'>
      <div className='flex justify-between w-full'>
        <div><img src={UnloadedBagsIcon} alt="Unloaded-Bags" /></div>
        <div>
          <Menu as="div" className="relative z-20 px-2 mb-2 mt-2">
            <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span className="text-sm">{selectedCompany}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </Menu.Button>
            <Menu.Items className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
              {companies.map((company, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 cursor-pointer text-xs text-[#126568]`}
                      onClick={() => setSelectedCompany(company)}
                    >
                      {company}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>
      </div>

      <div className='flex justify-between w-full'>
        <div className="text-sm">Total Un-Loaded Bags</div>
        <div>
          <Menu as="div" className="relative z-10 bg-[#E5F7F8] px-2 border border-transparent rounded text-[#126568] mb-2">
            <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span className="text-sm">{selectedTimeRange}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </Menu.Button>
            <Menu.Items className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
              {timeRanges.map((range, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 cursor-pointer text-xs text-[#126568]`}
                      onClick={() => setSelectedTimeRange(range)}
                    >
                      {range}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>
      </div>

      <div className='flex justify-between w-full'>
        <div className='font-bold text-[4vh] text-[#126568]'>{ unloadedBagsCount }</div>
        <div className='flex flex-col'>
          <div
            className={`py-1 flex justify-end text-sm ${
              unloadedBagsChangePercentage > 0
                ? 'text-green-800'
                : unloadedBagsChangePercentage < 0
                ? 'text-red-600'
                : 'text-gray-600'
            }`}
          >
            <span
              className={`px-2 border-transparent rounded ${
                unloadedBagsChangePercentage > 0
                  ? 'bg-emerald-300'
                  : unloadedBagsChangePercentage < 0
                  ? 'bg-red-100'
                  : 'bg-gray-200'
              }`}
            >
              {unloadedBagsChangePercentage.toFixed(2)}%
            </span>
          </div>
          <div className="text-xs mb-2">As compared to last week.</div>
        </div>
      </div>
    </div>
  );
}

export default UnloadBags;
