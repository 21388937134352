import React, { useState, useEffect } from 'react';
import Handling from '../../Assets/SVG/DashBoard/Handling.svg';
import HandlingRateGraph from './HandlingRateGraph';
import { HandlingRateModal } from '../../components/Dashboard/modals/HandlingRateModal';
import { RiArrowDropDownLine } from "react-icons/ri";
import { fetchHandlingRate, sethandlingRatefilterPeriod, fetchCnFNames } from '../../feature/Dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';

const HandlingRateHead = () => {
  const [activeFilter, setActiveFilter] = useState('7 days');
  const { locationSelected } = useSelector((state) => state.navbar);
  const [selectedCompany, setSelectedCompany] = useState("All C&F");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filters = ['7 days', '1 month'];

  const formatString = (str) => str.toLowerCase().replace(/ /g, '_');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCnFNames({ 'state': locationSelected }));
    setSelectedCompany('All C&F');
    dispatch(fetchHandlingRate({ "cnf_name": '', "period": formatString(activeFilter) }));
  }, [dispatch, locationSelected]);

  const raw_cnf = useSelector((state) => state.dashboard.CnFNames);
  const companies = ["All C&F", ...raw_cnf];  // Add "All" option

  useEffect(() => {
    dispatch(sethandlingRatefilterPeriod(formatString(activeFilter)));
  }, [activeFilter, dispatch]);

  useEffect(() => {
    dispatch(fetchHandlingRate({
      "cnf_name": selectedCompany === "All C&F" ? '' : selectedCompany,
      "period": formatString(activeFilter)
    }));
  }, [selectedCompany, activeFilter, dispatch]);

  let [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    setIsDropdownOpen(false);  // Close the dropdown
  };

  return (
    <div className="p-4 px-7 bg-white rounded-lg mx-auto flex flex-col gap-4 items-start h-[60vh]">
      <HandlingRateModal isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className="flex items-center justify-between w-full mb-4">
        <h1 className="text-[1.75vh] font-semibold text-[#126568]">Handling Rate</h1>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2 bg-[#E5F7F8] rounded-md px-2 py-3 cursor-pointer" onClick={handleClick}>
            <img src={Handling} alt="Handling" className="w-[1.5vh] h-[1.5vh] text-[#126568]" />
            <span className="text-[#126568] text-[1.5vh]">Analysis</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative">
              <div 
                className="flex items-center space-x-2 bg-[#F4F6F8] rounded-md px-2 py-2 cursor-pointer"
                onClick={handleDropdownToggle}
              >
                <span className="text-sm">{selectedCompany}</span>
                <RiArrowDropDownLine className="w-5 h-5" />
              </div>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-0 max-h-[30vh] overflow-auto z-10">
                  {companies.map((company, index) => (
                    <div
                      key={index}
                      onClick={() => handleCompanySelect(company)}
                      className="px-4 py-2 cursor-pointer text-xs text-[#126568] hover:bg-[#e5f7f8]"
                    >
                      {company}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="bg-[#F4F6F8] rounded-md px-2 py-1 flex space-x-2">
              {filters.map((filter) => (
                <div
                  key={filter}
                  onClick={() => setActiveFilter(filter)}
                  className={`px-2 py-2 rounded-md cursor-pointer text-[1.5vh] ${activeFilter === filter
                    ? 'bg-[#126568] text-white'
                    : 'bg-[#F4F6F8] text-[#126568]'
                    } hover:bg-[#126568] hover:text-white transition-colors`}
                >
                  {filter}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <HandlingRateGraph />
    </div>
  );
}

export default HandlingRateHead;
