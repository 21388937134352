import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
const NoData = () => {
    return (
        <div className="flex flex-col items-center justify-center h-full text-center p-4 h-[50vh]">
            <ExclamationCircleIcon className="w-10 h-10 mb-2 text-[#126568]" />
            <h1 className="text-xl font-semibold mb-2">Data Not Found!</h1>
            <p className="text-sm text-gray-600 w-[20vw]">Please select the location first, followed by the C&F company name, to view table data.</p>
        </div>
    )
}

export default NoData