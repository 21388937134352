import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { fetchUnloadingAnalysis } from '../../feature/Location/LocationSlice';

const UnloadingAnalysis = () => {
  const dispatch = useDispatch();
  const { unloadingAnalysis, selectedCompany } = useSelector((state) => state.location);
  const { locationSelected } = useSelector((state) => state.navbar);

  // Local state for duration
  const [localDuration, setLocalDuration] = useState('this_month');

  const durationLabels = {
    this_week: 'Current Week',
    this_month: 'Current Month',
  };

  useEffect(() => {
    if (locationSelected && selectedCompany) {
      dispatch(fetchUnloadingAnalysis({ cnf: selectedCompany, state: locationSelected, duration: localDuration }));
    }
  }, [dispatch, locationSelected, selectedCompany, localDuration]);

  const handleDurationChange = (newDuration) => {
    setLocalDuration(newDuration); // Update local duration state
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="bg-white rounded-lg p-2 shadow-xl text-sm">
          <div className="flex gap-2">
            {payload.map((item, index) => (
              <div key={index} className="mr-2">
                <p className="m-0 text-xs text-teal-500 font-600">{data.name}</p>
                <p className="m-0 text-black text-base font-bold opacity-0.5 font-12">{`${item.value}% `}</p>
                <p className={`m-0 text-sm font-bold text-teal-500 ${item.fill}`}>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const transformedData = (unloadingAnalysis || []).map(item => ({
    name: item.date,
    HandlingRate: item.unloaded_bags,
  }));

  return (
    <div className="p-2 bg-white rounded-lg">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold text-black">Unloading Analysis</h1>
        <Menu as="div" className="relative">
          <Menu.Button className="flex items-center bg-[#e5f7f8] text-[#126568] py-2 px-4 rounded-lg focus:outline-none">
            {durationLabels[localDuration]}
            <ChevronDownIcon className="w-5 h-5 ml-2 text-[#126568]" aria-hidden="true" />
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="p-1">
              {Object.entries(durationLabels).map(([value, label]) => (
                <Menu.Item key={value}>
                  {({ active }) => (
                    <button
                      onClick={() => handleDurationChange(value)}
                      className={`${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      {label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      </div>
      <div className="mt-2 h-40">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={transformedData}
            margin={{ top: 10, bottom: 0, left: -15 }}
          >
            <defs>
              <linearGradient id="gradientColor" x1="0" y1="0" x2="1" y2="1">
                <stop offset="0%" stopColor="#00ADB5" stopOpacity={1} />
                <stop offset="100%" stopColor="#004C4F" stopOpacity={1} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" vertical={false} className="bg-gray-100" />
            <XAxis dataKey="name" tick={{ fill: '#00ADB5' }} className="text-xs font-bold" axisLine={{ strokeDasharray: '3 3' }} />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: '#00ADB5' }} className="text-xs font-bold" tickFormatter={(value) => `${value}%`} />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
            <Bar dataKey="HandlingRate" barSize={8} fill="url(#gradientColor)" className="no-hover" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default UnloadingAnalysis;
