import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../feature/UserSlice';
import locationReducer from '../feature/Location/LocationSlice';
import stockReducer from '../feature/Stocks/StockSlice';
import dashboardReducer from '../feature/Dashboard/dashboardSlice';
import truckReducer from '../feature/Trucks/TruckSlice'
import navbarReducer from '../feature/Navbar/NavbarSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    location:locationReducer,
    stocks: stockReducer,
    dashboard: dashboardReducer,
    truck: truckReducer,
    navbar: navbarReducer
  },
});

export default store;