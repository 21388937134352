import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getAuthToken = () => localStorage.getItem("Authorization");

// Async thunk for fetching total stocks with duration
export const fetchTotalStocks = createAsyncThunk(
  'stocks/fetchTotalStocks',
  async ({ cnf, state, duration }) => {
    try {
      const response = await axios.get('https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/total_stocks_availabe/', {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        },
        params: { cnf, state, duration }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Async thunk for fetching stock analysis table data with duration
export const fetchStockAnalysisTableData = createAsyncThunk(
  'stocks/fetchStockAnalysisTableData',
  async ({ cnf, state, duration }) => {
    try {
      const response = await axios.get('https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/stock_analysis/', {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`
        },
        params: { cnf, state, duration }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// StockSlice to manage stocks state
const StockSlice = createSlice({
  name: 'stocks',
  initialState: {
    totalStocks: null,
    stockAnalysisData: null,
    stockAnalysisDataState: null,
    duration: 'past_12_months', // Default value for duration
    status: 'idle',
    error: null,
  },
  reducers: {
    setDuration(state, action) {
      const validDurations = ['past_12_weeks', 'past_12_months'];
      if (validDurations.includes(action.payload)) {
        state.duration = action.payload;
      } else {
        state.error = 'Invalid duration selected';
      }
    },
    resetStockFilters(state) {
      state.totalStocks = null;
      state.stockAnalysisData = null;
      state.stockAnalysisDataState = null;
      state.duration = 'past_12_months'; // Reset to default duration
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalStocks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTotalStocks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.totalStocks = action.payload;
      })
      .addCase(fetchTotalStocks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchStockAnalysisTableData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStockAnalysisTableData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stockAnalysisData = action.payload;
      })
      .addCase(fetchStockAnalysisTableData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {
  setDuration,
  resetStockFilters,
} = StockSlice.actions;

export default StockSlice.reducer;
