import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { makeRequest } from '../../api/api';

const navbarSlice = createSlice({
    name: 'navbar',
    initialState: {
      locationSelected: 'Tamil Nadu',
    },
    reducers: {
      setLocationSelected(state, action) {
        state.locationSelected = action.payload;
      },
    },
  });
  
  export const { setLocationSelected } = navbarSlice.actions;
  export default navbarSlice.reducer;