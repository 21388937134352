// src/features/user/UserSlice.jsx
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for logging in the user
export const loginUser = createAsyncThunk('user/loginUser', async (userCredentials) => {
  try {
    const response = await axios.post('https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/user_profile/login/', userCredentials);
    const { access, refresh } = response.data;

    if (access) {
      localStorage.setItem("Authorization", access);
    } else {
      throw new Error('Access token not found in response');
    }

    if (refresh) {
      localStorage.setItem("RefreshToken", refresh);
    }

    return response.data;
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("RefreshToken");
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.access;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
