import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiChevronDown } from 'react-icons/bi';
import truckIcon from "../../Assets/SVG/Sidebar/truck.svg";
import { Button, Menu } from '@headlessui/react';
import { fetchCnfByState, fetchTrucksByCnf, setSelectedCompany, setSelectedTruck, resetFilters } from '../../feature/Trucks/TruckSlice';

function TruckFilters() {
  const dispatch = useDispatch();
  const cnfNames = useSelector((state) => state.truck.cnfNames);
  const truckRegistrationNumbers = useSelector((state) => state.truck.truckRegistrationNumbers);
  const selectedCompany = useSelector((state) => state.truck.selectedCompany);
  const selectedTruck = useSelector((state) => state.truck.selectedTruck);
  const { locationSelected } = useSelector((state) => state.navbar);

  // Log locationSelected for debugging
 

  useEffect(() => {
    dispatch(fetchCnfByState(locationSelected));
  }, [locationSelected, dispatch]);

  useEffect(() => {
    if (Array.isArray(cnfNames) && cnfNames.length > 0) {
      dispatch(setSelectedCompany(cnfNames[0]));
    }
  }, [cnfNames, dispatch]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(fetchTrucksByCnf(selectedCompany));
    }
  }, [selectedCompany, dispatch]);

  useEffect(() => {
    if (Array.isArray(truckRegistrationNumbers) && truckRegistrationNumbers.length > 0) {
      dispatch(setSelectedTruck(truckRegistrationNumbers[0]));
    }
  }, [truckRegistrationNumbers, dispatch]);

  const handleStateChange = (state) => {
    dispatch(resetFilters());
    dispatch(fetchCnfByState(state)); // Fetch CNF names for the selected state
  };

  const handleCompanyChange = (company) => {
    dispatch(setSelectedCompany(company));
    dispatch(setSelectedTruck(''));
  };

  const handleTruckChange = (truck) => {
    dispatch(setSelectedTruck(truck));
  };

  return (
    <div className="p-3 bg-white rounded-md m-2 w-100">
      <h1 className="text-sm text-[#737373] font-semibold mb-1">Filters</h1>

      <div className='flex w-[100%] gap-4'>
        <div className='w-[50%]'>
          <div className="flex items-center text-xs mb-2">
            <p className="text-[#737373] mr-1">Select the C&F Company</p>
          </div>

          <Menu as="div" className="relative">
            <Menu.Button className="p-2 bg-white border-2 border-[#BCD8DA] flex items-center justify-between rounded-md w-full">
              <span className="text-[#126568] text-xs font-semibold">{selectedCompany || "Select Company"}</span>
              <BiChevronDown className="h-5 w-5" style={{ color: '#126568' }} />
            </Menu.Button>
            <Menu.Items className="absolute mt-5 w-full bg-white border border-[#BCD8DA] rounded-md shadow-lg z-10 max-h-[30vh] overflow-auto">
              {cnfNames.length > 0 ? (
                cnfNames.map((company, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        onClick={() => handleCompanyChange(company)}
                        className={`${active ? 'bg-[#e5f7f8]' : ''} w-full text-left px-4 py-2 text-xs text-[#126568]`}
                      >
                        {company}
                      </button>
                    )}
                  </Menu.Item>
                ))
              ) : (
                <div className="px-4 py-2 text-xs text-[#126568]">No companies available</div>
              )}
            </Menu.Items>
          </Menu>
        </div>

        <div className='w-[50%]'>
          <div className="flex items-center text-xs mb-2">
            <p className="text-[#737373] mr-1">Select Trucks in</p>
            <span className="text-[#126568] font-semibold">{selectedCompany || "Select Company"}</span>
          </div>

          <Menu as="div" className="relative">
            <Menu.Button className="p-2 bg-white border-2 border-[#BCD8DA] flex items-center justify-between rounded-md w-full mb-3">
              <div className="flex items-center gap-1">
                <img className="h-4 w-4 mr-1" src={truckIcon} alt="Truck-icon" />
                <span className="text-[#126568] text-xs font-semibold">{selectedTruck || "Select Truck"}</span>
              </div>
              <BiChevronDown className="h-5 w-5" style={{ color: '#126568' }} />
            </Menu.Button>
            <Menu.Items className="absolute mt-2 w-full bg-white border border-[#BCD8DA] rounded-md shadow-lg z-10 max-h-[30vh] overflow-auto">
              {Array.isArray(truckRegistrationNumbers) && truckRegistrationNumbers.length > 0 ? (
                truckRegistrationNumbers.map((truck, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <button
                        onClick={() => handleTruckChange(truck)}
                        className={`${active ? 'bg-[#e5f7f8]' : ''} w-full text-left px-4 py-2 text-xs text-[#126568]`}
                      >
                        {truck}
                      </button>
                    )}
                  </Menu.Item>
                ))
              ) : (
                <div className="px-4 py-2 text-xs text-[#126568]">No trucks available</div>
              )}
            </Menu.Items>
          </Menu>
        </div>
      </div>

      {/* <div className="flex justify-end space-x-3 mt-3">
        <Button onClick={() => handleStateChange(locationSelected)} className="px-3 py-1 text-black border rounded-md hover:bg-[#e5f7f8] transition-colors text-xs"> */}
      {/* <div className="flex justify-end space-x-3 mt-3">
        <Button onClick={() => handleStateChange("Tamil Nadu")} className="px-3 py-1 text-black border rounded-md hover:bg-[#e5f7f8] transition-colors text-xs">
          Reset
        </Button>
        <Button className="px-3 py-1 bg-[#126568] text-white border border-[#126568] rounded-md hover:bg-[#0f4c51] transition-colors text-xs">
          Apply
        </Button>
      </div> */}
    </div>
  );
}

export default TruckFilters;
