import React from 'react';
import Phone from '../Assets/SVG/phone.svg'; // Import the SVG file

const OwnerName = ({type, title, name, id}) => {

  const renderDetails = (type) => {
    switch (type) {
      case 'owner':
        return (
          <div className="flex items-center text-[1.5vh] text-[#767676]">
            <img src={Phone} alt="Phone Icon" className="h-[2vh] mr-2" />
            <span>+91 {id}</span>
          </div>
        );
      case 'warehouse':
        return (
          <div className="flex items-center text-sm text-[#767676]">
            <p>{id}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-[100%] p-5 bg-white rounded-md h-[18vh] overflow-auto">
      <h1 className="text-[1.5vh] font-bold text-black ">{title}</h1>
      <h2 className="text-[3vh] font-bold text-[#126568] ">{name}</h2>
      {renderDetails(type)}
    </div>
  );
}

export default OwnerName;