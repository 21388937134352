import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Custom Tooltip component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="bg-white rounded-lg p-2 shadow-xl w-50 text-sm">
        <div className="flex gap-2">
          {payload.map((item, index) => (
            <div key={index} className="mr-2">
              <p className={`m-0 text-sm font-bold text-teal-500 ${item.fill}`}>{item.name}</p>
              <p className="m-1 text-gray-500 text-base font-500 opacity-0.5 font-12">{`${item.value} bags`}</p>
            </div>
          ))}
        </div>
        <p className="ml-9 text-xs text-teal-500 font-normal">{data.name}</p>
      </div>
    );
  }
  return null;
};

// Custom Legend component
const CustomLegend = () => (
  <div className="flex mb-5">
    <div className="flex items-center mr-2">
      <span className="bg-teal-500 w-3 h-3 inline-block mr-1"></span>
      <span>Loaded</span>
    </div>
    <div className="flex items-center">
      <span className="bg-black w-3 h-3 inline-block mr-1"></span>
      <span>Unloaded</span>
    </div>
  </div>
);

const TruckLoadUnloadGraph = ({ data }) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={data}
      margin={{ top: 10, right: 20, left: 20, bottom: 40 }}
      barCategoryGap={0}
      barSize={25}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} className="bg-gray-100" />
      <XAxis
        dataKey="name"
        axisLine={false}
        tickLine={false}
        tick={{ fill: '#00ADB5' }}
        className="text-xs"
      />
      <YAxis
        label={{ value: 'Bags', angle: -90, position: 'insideLeft', fill: 'gray' }}
        axisLine={false}
        tickLine={false}
        tick={{ fill: '#00ADB5' }}
        className="text-xs"
      />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
      <Legend content={<CustomLegend />} verticalAlign="top" />
      <Bar dataKey="Loaded" fill="#00ADB5" />
      <Bar dataKey="Unloaded" fill="#242325" />
    </BarChart>
  </ResponsiveContainer>
);

export default TruckLoadUnloadGraph;
