import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import Truck1 from '../../Assets/SVG/DashBoard/truck1.svg';
import Truck2 from '../../Assets/SVG/DashBoard/truck2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCnFNames, setTruckInfoByCnFfilterPeriod, fetchTruckInfoByCnF } from '../../feature/Dashboard/dashboardSlice';

const TotalTrucksStatsHead = () => {
  const [activeFilter, setActiveFilter] = useState('1 month');
  const [selectedCompany, setSelectedCompany] = useState('All C&F');
  const [totalTrucks, setTotalTrucks] = useState(0);
  const [truck70Feet, setTruck70Feet] = useState(0);
  const [truck120Feet, setTruck120Feet] = useState(0);
  const [truckCapacity70Feet, setTruckCapacity70Feet] = useState(0);
  const [truckCapacity120Feet, setTruckCapacity120Feet] = useState(0);

  const { locationSelected } = useSelector((state) => state.navbar);

  const filters = ['7 days', '1 month'];
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);
  const truck_info = useSelector((state) => state.dashboard.truckInfoByCnf);

  useEffect(() => {
    dispatch(fetchCnFNames({ state: locationSelected }));
    dispatch(fetchTruckInfoByCnF({ cnf_name: '', period: formatString(activeFilter) }));
  }, [dispatch, locationSelected, activeFilter]);

  const raw_cnf = useSelector((state) => state.dashboard.CnFNames);

  useEffect(() => {
    if (activeFilter) {
      dispatch(setTruckInfoByCnFfilterPeriod(formatString(activeFilter)));
    }
  }, [activeFilter, dispatch]);

  useEffect(() => {
    // Fetch truck info when the selected company or filter period changes
    dispatch(fetchTruckInfoByCnF({ cnf_name: selectedCompany === 'All C&F' ? '' : selectedCompany, period: formatString(activeFilter) }));
  }, [selectedCompany, activeFilter, dispatch]);

  useEffect(() => {
    if (truck_info) {
      setTotalTrucks(truck_info.total_trucks);
      setTruck70Feet(truck_info.trucks_less_equal_25_ton);
      setTruck120Feet(truck_info.trucks_more_than_25_ton);
      setTruckCapacity70Feet(truck_info.capacity_less_equal_25_ton);
      setTruckCapacity120Feet(truck_info.capacity_more_than_25_ton);
    }
  }, [truck_info]);

  useEffect(() => {
    if (error) {
      setTotalTrucks(0);
      setTruck70Feet(0);
      setTruck120Feet(0);
      setTruckCapacity70Feet(0);
      setTruckCapacity120Feet(0);
    }
  }, [error]);

  const formatString = (str) => str.toLowerCase().replace(/ /g, '_');

  const companies = raw_cnf || [];

  const truckData = {
    Truck1: {
      heading: '10-25 Tons. Truck',
      numberOfTrucks: 220,
      avgCapacity: 320, // in Bags
      svgIcon: <img src={Truck1} alt="Truck 1" className="text-[#126568]" />,
    },
    Truck2: {
      heading: 'Over 25 Tons. Truck',
      numberOfTrucks: 150,
      avgCapacity: 500, // in Bags
      svgIcon: <img src={Truck2} alt="Truck 2" className="text-[#126568]" />,
    },
  };

  return (
    <div className="p-5 bg-white rounded-lg mx-auto h-[55vh]">
      <div className="flex justify-between items-center">
        <h1 className="text-[2vh] font-400 text-[#767676]">Total Trucks</h1>
        <div className="bg-[#F4F6F8] rounded-md px-1 py-1 flex space-x-2">
          {filters.map((filter) => (
            <div
              key={filter}
              onClick={() => setActiveFilter(filter)}
              className={`px-1 py-1 rounded-md cursor-pointer text-[1.5vh] ${
                activeFilter === filter
                  ? 'bg-[#126568] text-white'
                  : 'bg-[#F4F6F8] text-[#126568]'
              } hover:bg-[#126568] hover:text-white transition-colors`}
            >
              {filter}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <span className="text-[3vh] text-[#126568] font-medium">{totalTrucks}</span>
        <Menu as="div" className="z-10 relative">
          <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
            <span className="text-sm">{selectedCompany}</span>
            <RiArrowDropDownLine className="w-5 h-5" />
          </Menu.Button>
          <Menu.Items className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 cursor-pointer text-xs text-[#126568]`}
                  onClick={() => setSelectedCompany('All C&F')}
                >
                  All C&F
                </div>
              )}
            </Menu.Item>
            {companies.map((company, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 cursor-pointer text-xs text-[#126568]`}
                    onClick={() => setSelectedCompany(company)}
                  >
                    {company}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
      <hr className="mt-3" />

      {/* Truck 1 Data */}
      <div className="flex flex-col mt-4">
        <div className="flex justify-between items-center pb-3">
          <div>
            <h2 className="text-[1.5vh] font-500 text-[#767676]">{truckData.Truck1.heading}</h2>
            <p className="text-[3vh] text-[#126568] font-medium">{truck70Feet}</p>
            <p className="text-[1.5vh] font-500 text-[#767676]">Avg. Capacity: {truckCapacity70Feet} Bags</p>
          </div>
          <div className="ml-auto">
            {truckData.Truck1.svgIcon}
          </div>
        </div>
        <hr className="my-4" />
      </div>

      {/* Truck 2 Data */}
      <div className="flex flex-col">
        <div className="flex justify-between items-center pb-3">
          <div>
            <h2 className="text-[1.5vh] font-500 text-[#767676]">{truckData.Truck2.heading}</h2>
            <p className="text-[3vh] text-[#126568] font-medium">{truck120Feet}</p>
            <p className="text-[1.5vh] font-500 text-[#767676]">Avg. Capacity: {truckCapacity120Feet} Bags</p>
          </div>
          <div className="ml-auto">
            {truckData.Truck2.svgIcon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalTrucksStatsHead;
