import {
  Field,
  Label,
  Select,
  Button,
  Dialog,
  Description,
  DialogPanel,
  DialogTitle,
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { ReactComponent as TruckIcon } from "../../Assets/SVG/DashBoard/iconoir_truck.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTruckList,
  fetchHandlingRateByTruck,
  setDuration,
} from "../../feature/Location/LocationSlice"; // Adjust the path to your LocationSlice

function HandlingRateModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const truckList = useSelector((state) => state.location.truckList);
  const selectedCompany = useSelector(
    (state) => state.location.selectedCompany
  );
  const { locationSelected } = useSelector((state) => state.navbar);
  // const locationSelected = useSelector((state) => state.location.locationSelected);
  const handlingRateByTruck = useSelector(
    (state) => state.location.handlingRateByTruck
  );
  const duration = useSelector((state) => state.location.duration);

  const [queryTruck, setQueryTruck] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("This Week");
  const [selectedTruck, setSelectedTruck] = useState(truckList[0] || "");

  useEffect(() => {
    if (locationSelected && selectedCompany) {
      dispatch(
        fetchTruckList({ cnf: selectedCompany, state: locationSelected })
      );
    }
  }, [dispatch, locationSelected, selectedCompany]);

  useEffect(() => {
    // Update the selected truck if the truck list changes
    setSelectedTruck(truckList[0] || "");
  }, [truckList]);

  useEffect(() => {
    if (selectedTruck) {
      dispatch(
        fetchHandlingRateByTruck({
          truck: selectedTruck,
          cnf: selectedCompany,
          state: locationSelected,
          duration,
        })
      );
    }
  }, [dispatch, selectedTruck, selectedCompany, locationSelected, duration]);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  // Handle month selection
  const handleMonthChange = (event) => {
    const selectedDuration =
      event.target.value === "This Week" ? "this_week" : "this_month";
    dispatch(setDuration(selectedDuration));
    setSelectedMonth(event.target.value);
  };

  // Array of month options for the select dropdown
  const monthOptions = ["This Week", "This Month"];

  return (
    <>
      <Dialog open={isOpen} as="div" className="relative z-10" onClose={close}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            className="fixed inset-0 bg-black/30 backdrop-blur-sm"
            aria-hidden="true"
          ></div>
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              className={`w-[1600px] h-[340px] max-w-3xl rounded-3xl bg-white p-6 shadow-lg transition-all transform ${
                isOpen ? "scale-100 opacity-100" : "scale-95 opacity-0"
              }`}
            >
              <Button
                onClick={close}
                className="absolute top-7 right-6 text-red-500 hover:text-red-700 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </Button>
              <DialogTitle as="h3" className="text-xl font-medium text-black">
                Handling Rate Analysis
              </DialogTitle>
              <Description className="mt-1">
                <span className="text-xs" style={{ color: "#4a4a4a" }}>
                  Select truck in{" "}
                </span>
                <span className="text-teal-800 font-bold text-xs">
                  {selectedCompany || "Company Name"}
                </span>
                <span className="text-xs">.</span>
                <br />
                <div className="relative">
                  <Combobox
                    value={selectedTruck}
                    onChange={(value) => setSelectedTruck(value)}
                    onClose={() => setQueryTruck("")}
                  >
                    <div className="relative">
                      <TruckIcon className="absolute inset-y-0 left-0 mt-1 pl-2 h-6 w-6" />
                      <ComboboxInput
                        className={clsx(
                          "w-full rounded-lg border border-teal-600 bg-white py-1.5 pr-8 pl-10 text-sm text-black",
                          "focus:outline-none focus:outline-2 focus:outline-teal-600"
                        )}
                        displayValue={(truckNumber) => truckNumber}
                        onChange={(event) => setQueryTruck(event.target.value)}
                      />
                      <ComboboxButton className="absolute inset-y-0 right-0 px-2.5">
                        <ChevronDownIcon className="h-5 w-5 text-teal-800 hover:text-teal-600" />
                      </ComboboxButton>
                    </div>
                    <div className="absolute z-10 mt-1 w-full">
                      <ComboboxOptions
                        className={clsx(
                          "z-10 w-full rounded-xl border border-teal-800 bg-white p-1",
                          "transition duration-100 ease-in opacity-100",
                          "top-full mt-1 overflow-hidden"
                        )}
                      >
                        {truckList.map((truckNumber, index) => (
                          <ComboboxOption
                            key={index}
                            value={truckNumber}
                            className="flex cursor-default items-center gap-2 rounded-lg py-1 px-1 select-none hover:bg-teal-100"
                          >
                            <CheckIcon className="invisible h-4 w-4 text-white group-data-[selected]:visible" />
                            <div className="text-sm text-black">
                              {truckNumber}
                            </div>
                          </ComboboxOption>
                        ))}
                      </ComboboxOptions>
                    </div>
                  </Combobox>

                  <div className="grid grid-cols-2 gap-4 items-start">
                    <div className="flex mt-3">
                      <div className="flex flex-1 flex-col">
                        <span className="text-gray-500">Total Loaded</span>
                        <span className="text-teal-800 font-bold w-28 text-[35px] bold">
                          {handlingRateByTruck
                            ? handlingRateByTruck.Total_loaded
                            : "N/A"}
                        </span>
                        <div className="flex flex-3 bg-green-200 bold rounded-md p-1 min-w-[80px] w-auto h-6 flex items-center ">
                          <label className="text-center text-green-800">
                            +
                            {handlingRateByTruck
                              ? handlingRateByTruck.loaded_percentage_change
                              : "N/A"}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="mt-20 mx-20 divider text-gray-500"></div>
                      <div className="flex flex-1 flex-col">
                        <span className="text-gray-500">Total Unloaded</span>
                        <span className="text-teal-800 font-bold w-28 text-[35px] bold">
                          {handlingRateByTruck
                            ? handlingRateByTruck.Total_unloaded
                            : "N/A"}
                        </span>
                        <div className="flex flex-3 bg-red-200 bold rounded-md p-1 min-w-[80px] w-auto h-6 flex items-center">
                          <label className="text-red-800">
                            -
                            {handlingRateByTruck
                              ? handlingRateByTruck.unloaded_percentage_change
                              : "N/A"}{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end items-start mt-5s">
                      <Field>
                        <div className="relative">
                          <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            className={clsx(
                              "w-35 h-5 max-h-xs max-w-md mt-3 mr-2 block w-full appearance-none rounded-md border-none bg-teal-100 py-0.5 px-3.5 text-[12px] text-teal-800",
                              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
                              "*:text-black"
                            )}
                          >
                            {monthOptions.map((month) => (
                              <option
                                key={month}
                                className="text-[10px]"
                                value={month}
                              >
                                {month}
                              </option>
                            ))}
                          </Select>
                          <ChevronDownIcon
                            className="group pointer-events-none absolute top-0.5 right-2.5 size-4 fill-teal-800"
                            aria-hidden="true"
                          />
                        </div>
                      </Field>
                    </div>
                  </div>
                  <br />
                  <div className="w-full mx-auto">
                    <div className="relative pt-1">
                      <div className="relative flex">
                        <div className="flex items-center justify-between mb-2 p-1">
                          <span className="text-sm font-medium text-cyan-700">
                            Loaded <span className="text-gray-300">|</span>
                            {handlingRateByTruck
                              ? handlingRateByTruck.Loaded_percentage
                              : "N/A"}
                          </span>
                        </div>
                        <div className="flex absolute right-0 justify-end right-0 mb-2 p-1">
                          <span className="text-sm font-medium text-black">
                            Un-Loaded <span className="text-black">|</span>{" "}
                            {handlingRateByTruck
                              ? handlingRateByTruck.Unloaded_percentage
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="relative flex w-full bg-gray-200 rounded-full h-2">
                        <div
                          className="bg-cyan-500 rounded-full h-2 mr-1"
                          style={{
                            width: `${
                              handlingRateByTruck
                                ? handlingRateByTruck.Loaded_percentage
                                : "N/A"
                            }%`,
                          }}
                        ></div>
                        <div
                          className="bg-black rounded-full h-2 p-1 w[33]"
                          style={{
                            width: `${
                              handlingRateByTruck
                                ? handlingRateByTruck.Unloaded_percentage
                                : "N/A"
                            } %`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Description>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default HandlingRateModal;
