import React from 'react';

const FullAddress = ({ type, address }) => {

  const fontSize = type === "location" ? '2.5vh' : '1.75vh'
  return (

    <div className="w-[100%] p-5 bg-white rounded-md h-[18vh]">
      <h1 className="text-[1.5vh] font-bold text-black ">Full Address</h1>
      <p className={`text-[#126568] text-[${fontSize}] mt-[1vh]`}>
        {address}
      </p>
    </div>
  );
}

export default FullAddress;
