import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Helper function to get the auth token
const getAuthToken = () => localStorage.getItem('Authorization');

// Define async thunks for the API requests

// Fetch CNF by State
export const fetchCnfByState = createAsyncThunk(
  'truck/fetchCnfByState',
  async (state) => {
    const response = await axios.get(
      `https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/cnf-by-state/`,
      {
        params: { state }, // Pass the state as a query parameter
      }
    );
    return response.data;
  }
);

// Fetch Trucks by CNF
export const fetchTrucksByCnf = createAsyncThunk(
  'truck/fetchTrucksByCnf',
  async (cnf_name, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        'https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/trucks-by-cnf/',
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { cnf_name },
        }
      );
      return response.data.truck_registration_numbers;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while fetching trucks by CNF');
    }
  }
);

export const fetchMonthlyTruckData = createAsyncThunk(
  'truck/fetchMonthlyTruckData',
  async ({ state, truckRegistrationNumber, cnfName, period }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        'https://cement-automation-backend-vgoekfgvwa-el.a.run.app/api/cement_count_automation/monthly-truck-data-table/',
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
          params: { state, truck_registration_number: truckRegistrationNumber, cnf_name: cnfName, period },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred while fetching monthly truck data');
    }
  }
);

// Define the slice
const truckSlice = createSlice({
  name: 'truck',
  initialState: {
    cnfNames: [],
    truckRegistrationNumbers: [],
    selectedCompany: '',
    selectedTruck: '',
    monthlyData: [],
    loading: false,
    cnftruck_loading: false,
    truckdata_loading: false,
    error: null,
  },
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setSelectedTruck: (state, action) => {
      state.selectedTruck = action.payload;
    },
    resetFilters: (state) => {
      state.selectedCompany = '';
      state.selectedTruck = '';
      state.truckRegistrationNumbers = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetchCnfByState cases
      .addCase(fetchCnfByState.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCnfByState.fulfilled, (state, action) => {
        state.loading = false;
        state.cnfNames = action.payload.cnf_names;
      })
      .addCase(fetchCnfByState.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetchTrucksByCnf cases
      .addCase(fetchTrucksByCnf.pending, (state) => {
        state.cnftruck_loading = true;
        state.error = null;
      })
      .addCase(fetchTrucksByCnf.fulfilled, (state, action) => {
        state.cnftruck_loading = false;
        state.truckRegistrationNumbers = action.payload;
      })
      .addCase(fetchTrucksByCnf.rejected, (state, action) => {
        state.cnftruck_loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMonthlyTruckData.pending, (state) => {
        state.truckdata_loading = true;
        state.error = null;
      })
      .addCase(fetchMonthlyTruckData.fulfilled, (state, action) => {
        state.truckdata_loading = false;
        state.monthlyData = action.payload;
      })
      .addCase(fetchMonthlyTruckData.rejected, (state, action) => {
        state.truckdata_loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedCompany, setSelectedTruck, resetFilters } = truckSlice.actions;
export default truckSlice.reducer;
