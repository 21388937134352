import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { RiArrowDropDownLine } from "react-icons/ri";
import ArrivalDepartureGraph from './ArrivalDepartureGraph';
import { fetchCnFNames, setArrivalDepartureAnalysisfilterPeriod, fetchArrivalDepartureAnalysis } from '../../feature/Dashboard/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';

const ArrivalDepartureHead = () => {
  const [activeFilter, setActiveFilter] = useState('7 days');
  const { locationSelected } = useSelector((state) => state.navbar);
  const [selectedCompany, setSelectedCompany] = useState("All C&F");

  const filters = ['7 days', '1 month'];
  const formatString = (str) => str.toLowerCase().replace(/ /g, '_');

  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCnFNames({ 'state': locationSelected }));
    setSelectedCompany('All C&F');
    dispatch(fetchArrivalDepartureAnalysis({ "cnf_name": '', "period": formatString(activeFilter) }));
  }, [dispatch, locationSelected]);

  const raw_cnf = useSelector((state) => state.dashboard.CnFNames);
  const companies = ['All C&F', ...raw_cnf]; // Add "All C&F" option to the beginning of the companies array

  useEffect(() => {
    dispatch(setArrivalDepartureAnalysisfilterPeriod(formatString(activeFilter)));
  }, [activeFilter, dispatch]);

  useEffect(() => {
    dispatch(fetchArrivalDepartureAnalysis({ "cnf_name": selectedCompany === 'All C&F' ? '' : selectedCompany, "period": formatString(activeFilter) }));
  }, [selectedCompany, activeFilter, dispatch]);

  return (
    <div className="p-5 bg-white rounded-lg mx-auto flex flex-col items-start h-[60vh]">
      <div className="flex items-center justify-between w-[100%] mb-10">
        <h1 className="text-[1.75vh] font-semibold text-[#126568]">Truck Arrival Analysis</h1>

        <div className="flex items-center space-x-2 mr-4">
          <Menu as="div" className="z-10 relative text-[1.5vh]">
            <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span className="text-sm">{selectedCompany}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </Menu.Button>
            <Menu.Items className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
              {companies.map((company, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 cursor-pointer text-xs text-[#126568]`}
                      onClick={() => setSelectedCompany(company)}
                    >
                      {company}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
          <div className="bg-[#F4F6F8] rounded-md px-2 py-1 flex space-x-2">
            {filters.map((filter) => (
              <div
                key={filter}
                onClick={() => setActiveFilter(filter)}
                className={`px-2 py-2 rounded-md cursor-pointer text-[1.5vh] ${
                  activeFilter === filter
                    ? 'bg-[#126568] text-white'
                    : 'bg-[#F4F6F8] text-[#126568]'
                } hover:bg-[#126568] hover:text-white transition-colors`}
              >
                {filter}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ArrivalDepartureGraph />
      <br />
      <br />
      <br />
    </div>
  );
};

export default ArrivalDepartureHead;
