import axios from 'axios';

const hostname = "https://cement-automation-backend-vgoekfgvwa-el.a.run.app";
// const hostname = "http://127.0.0.1:8000/";


const api = axios.create({
  baseURL:hostname,
  // baseURL: 'https://cement-automation-backend-vgoekfgvwa-el.a.run.app',
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Authorization");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


const makeRequest = async (method, url, data) => {
  console.log("data",data)
  try {
    const response = await api({
      method,
      url,
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error('API request failed:', error);
    if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      localStorage.removeItem('Authorization');
      localStorage.removeItem('RefreshToken');
      window.location.reload();
    }
    throw error;
  }
};

const makeRequestReduxApi = async (method, url, data) => {
  try {
    const response = await api({
      method,
      url,
      params: data,
    });
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 401)
    ) {
      console.error("Problem:",error.response.data.error)
    }
    throw error;
  }
};

export { api, makeRequest, makeRequestReduxApi };
