import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TruckRateAnalysisGraph from './TruckRateAnalysisGraph';
import { makeRequest } from '../../api/api';

const TruckRateAnalysis = () => {
  const selectedCompany = useSelector((state) => state.truck.selectedCompany);
  const selectedTruck = useSelector((state) => state.truck.selectedTruck);
  const { locationSelected } = useSelector((state) => state.navbar);


  const [data, setData] = useState([]);

  useEffect(() => {
    if (selectedCompany && selectedTruck) {
      const fetchData = async () => {
        try {
          const response = await makeRequest('GET', `/api/cement_count_automation/handling_rate_truck_info/?cnf_name=${selectedCompany}&truck_registration_number=${selectedTruck}&state=${locationSelected}`);
          const formattedData = response.map(item => ({
            name: item.month,
            HandlingRate: parseFloat(item.handling_rate_percentage.replace('%', '')),
            // Unloaded: item.unloading_sum,
          }));
          setData(formattedData);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      };
      fetchData();
    }
  }, [selectedCompany, selectedTruck, locationSelected]);
    return (

      <div className="pt-4 pl-4 pr-4 bg-white  rounded-lg w-full mx-auto flex flex-col items-start justify-between h-[50vh] ">
        <div className="flex items-center justify-between w-full mb-4">
          <h1 className="text-16 font-semibold text-[#242325]">Handling Rate Analysis</h1>
          <div className="flex items-center space-x-4">
          </div>
        </div>
        <TruckRateAnalysisGraph data={data}/>
      </div>
    );
}

export default TruckRateAnalysis