import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

const LoadUnLoadGraph = ({ data, type }) => {
  const legendClassName = type === "home" ? 'flex mb-5' : 'flex justify-end mb-5';
  
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="bg-white rounded-lg p-2 shadow-xl text-sm min-h-[6vh]">
          <div className="flex gap-1">
            {payload.map((item, index) => (
              <div key={index} className="mr-2 p-2">
                <span className={`w-3 h-3 mr-2 ${item.fill}`} />
                <p className={`m-0 text-sm font-bold text-${item.fill}`}>{item.name}</p>                
                <p className="m-0 text-gray-500 text-base font-500 opacity-0.5 font-12">{`${item.value} bags`}</p>
              </div>
            ))}
          </div>
          <p className="text-m text-teal-500 font-normal p-2">{data.name}</p>
        </div>
      );
    }
    return null;
  };

  const CustomLegend = () => (
    <div className={legendClassName}>
      <div className="flex items-center mr-2">
        <span className="bg-teal-500 w-3 h-3 inline-block mr-1"></span>
        <span>Loaded</span>
      </div>
      <div className="flex items-center">
        <span className="bg-black w-3 h-3 inline-block mr-1"></span>
        <span>Unloaded</span>
      </div>
    </div>
  );

  return (
    <ResponsiveContainer>
      <BarChart
        data={data.length > 0 ? data : [{ name: 'No Data', Loaded: 0, Unloaded: 0 }]}
        margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
        barCategoryGap={0}
        barSize={25}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} className="bg-gray-100" />
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={{ fill: '#00ADB5' }}
          className="text-xs"
          // label={{ value: 'Month', position: 'bottom', offset: -5, fill: '#00ADB5' }}
        />
        <YAxis
          label={{ value: 'Bags', angle: -90, position: 'insideLeft', fill: 'gray' }}
          axisLine={false}
          tickLine={false}
          tick={{ fill: '#00ADB5' }}
          className="text-xs"
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
        <Legend content={<CustomLegend />} verticalAlign="top" />
        {data.length === 0 ? (
          <Bar dataKey="Loaded" fill="#e2e8f0" barSize={25} radius={type === "home" ? undefined : [10, 10, 0, 0]}>
            <LabelList dataKey="Loaded" position="top" content={<CustomizedLabel />} />
          </Bar>
        ) : (
          <>
            <Bar dataKey="Loaded" fill="#00ADB5" radius={type === "home" ? undefined : [10, 10, 0, 0]} barSize={type === "home" ? undefined : 15 }>
            </Bar>
            <Bar dataKey="Unloaded" fill="#242325" radius={type === "home" ? undefined : [10, 10, 0, 0]} barSize={type === "home" ? undefined : 15}>
            </Bar>
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomizedLabel = (props) => {
  const { x, y, value } = props;
  return (
    <text x={x} y={y} dy={-200} fill="#4A5568" textAnchor="middle" fontSize={'3vh'} fontWeight={'600'}>
      No Data
    </text>
  );
};

export default LoadUnLoadGraph;
