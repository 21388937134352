import React, { useEffect, useState } from "react";
import OwnerName from "../component/OwnerName";
import HandlingRate from "../component/HandlingRate";
import FullAddress from "../component/FullAddress";
import LocationFilter from "../component/LocationFilter";
import LoadUnLoadGraph from "../container/Dashboard/LoadUnLoadGraph";
import StockTable from "../container/Stock/StockTable";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddressCompanyDetails,
  setSelectedLocation,
} from "../feature/Location/LocationSlice";
import {
  fetchStockAnalysisTableData,
  fetchTotalStocks,
  setDuration,
} from "../feature/Stocks/StockSlice";
import FilterModal from "../container/Stock/FilterModal";
import LocationWise from "../container/Dashboard/LocationWise";

const StockPage = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("past_12_months"); // Default to 'past_12_months'
  const dispatch = useDispatch();

  const {
    selectedLocation,
    selectedCompany,
    addressCompanyDetails,
  } = useSelector((state) => state.location);
  const { totalStocks, stockAnalysisData } = useSelector(
    (state) => state.stocks
  );

  const openFilterModal = () => setIsFilterModalOpen(true);
  const closeFilterModal = () => setIsFilterModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Set selected location from navbar slice
  useEffect(() => {
    if (selectedLocation) {
      dispatch(setSelectedLocation(selectedLocation));
    }
  }, [dispatch, selectedLocation]);

  // Fetch data based on selected location, company, and duration
  useEffect(() => {
    if (selectedLocation && selectedCompany) {
      dispatch(
        fetchAddressCompanyDetails({
          cnf: selectedCompany,
          state: selectedLocation,
        })
      );
      dispatch(
        fetchTotalStocks({
          cnf: selectedCompany,
          state: selectedLocation,
          duration: selectedDuration,
        })
      );
      dispatch(
        fetchStockAnalysisTableData({
          cnf: selectedCompany,
          state: selectedLocation,
          duration: selectedDuration,
        })
      );
    }
  }, [selectedLocation, selectedCompany, selectedDuration, dispatch]);

  // Dropdown options for duration filter
  const durations = [
    { label: "Past 12 Months", value: "past_12_months" },
    { label: "Past 12 Weeks", value: "past_12_weeks" },
  ];

  // Define table columns
  const tableColumns = [
    { Header: "Date", accessor: "date", headerClassName: "text-custom-blue" },
    {
      Header: "Stock In",
      accessor: "stock_in",
      headerClassName: "text-custom-green",
    },
    {
      Header: "Stock Out",
      accessor: "stock_out",
      headerClassName: "text-custom-black",
    },
  ];

  // Transform stock analysis data to chart data format
  const chartData = (stockAnalysisData || []).map((item) => ({
    name: new Date(item.date).toLocaleString("default", { month: "short" }), // Convert date to 'Mon.' format
    Unloaded: item.stock_out,
    Loaded: item.stock_in,
    amt: item.stock_in - item.stock_out, // If amt calculation is required
  }));

  // Handle duration selection
  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
    dispatch(setDuration(duration));
  };

  return (
    <div className="bg-[#F6F6F8]  border-gray-200 rounded-md overflow-y-scroll h-[85vh] ">
      <div className="flex flex-col gap-5 p-1">
        <LocationFilter isLocationPage={false} />
        <div className="flex justify-between gap-5">
          <OwnerName
            type={"warehouse"}
            title={"Warehouse ID"}
            name={"CF13242"}
            id={selectedCompany || "NA"}
          />
          <HandlingRate
            title={"Stocks Available"}
            value={totalStocks?.total_stocks_available || "NA"}
            unit={"Sacks"}
          />
          <FullAddress
            type="stock"
            address={addressCompanyDetails || "NA"}
          />
        </div>
        <div className="flex flex-col gap-2 p-5 bg-white rounded-lg">
          <div className="flex items-center justify-between py-2">
            <h1 className="text-xl font-bold text-black">Stock In/Out Data</h1>
            <div className="flex items-center gap-4">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex items-center gap-1 bg-white border border-gray-300 p-2 rounded text-sm text-gray-700 shadow-sm">
                    {durations.find((d) => d.value === selectedDuration)
                      ?.label || "Select Duration"}
                    <ChevronDownIcon
                      className="ml-2 -mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Menu.Items className="z-10 absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {durations.map((duration) => (
                    <Menu.Item key={duration.value}>
                      {({ active }) => (
                        <button
                          onClick={() =>
                            handleDurationChange(duration.value)
                          }
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } block w-full px-4 py-2 text-sm text-left`}
                        >
                          {duration.label}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Menu>
            </div>
          </div>
          <div className="flex justify-between gap-10">
            <div className="flex-1">
              <StockTable
                columns={tableColumns}
                data={stockAnalysisData || []}
              />
            </div>
            <div className="flex-1 border border-green-200 rounded-lg">
              <LoadUnLoadGraph type="stock" data={chartData} height={500} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex ${
          isModalOpen ? "blur-md" : ""
        } transition-all duration-300 ease-in-out`}
      >
        <LocationWise isOpen={isModalOpen} closeModal={closeModal} />
      </div>
      <FilterModal isOpen={isFilterModalOpen} onClose={closeFilterModal} />
    </div>
  );
};

export default StockPage;
