// src/features/user/UserSlice.jsx
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequestReduxApi } from '../../api/api';



// const createFetchThunk = (typePrefix, endpoint) => {
//   return createAsyncThunk(typePrefix, async (params, thunkAPI) => {
//     try {
//       const response = await makeRequestReduxApi("GET", endpoint, { params });
//       return response.data;
//     } catch (error) {
//       console.error('API request failed:', error);
//       return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
//     }
//   });
// };

// export const fetchFactoryNames = createFetchThunk('dashboaard/fetchFactoryNames', '/api/cement_count_automation/factory-names/');
// export const fetchCnFNames = createFetchThunk('cnf/fetchCnFNames', '/api/cement_count_automation/cnf-names/');
// export const fetchTotalLoadedBags = createFetchThunk('dashboaard/fetchTotalLoadedBags', '/api/cement_count_automation/loading-count/');
// export const fetchTotalUnloadedBags = createFetchThunk('dashboaard/fetchTotalUnloadedBags', '/api/cement_count_automation/unloading-count/');




export const fetchFactoryNames = createAsyncThunk('dashboaard/fetchFactoryNames',async (queryParams, thunkAPI) => {
    try {
      const response = await makeRequestReduxApi('GET', '/api/cement_count_automation/factory-names/', queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

export const fetchCnFNames = createAsyncThunk('cnf/fetchCnFNames',async (queryParams, thunkAPI) => {
    try {
      const response = await makeRequestReduxApi('GET', '/api/cement_count_automation/cnf-names/',queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

export const fetchTotalLoadedBags = createAsyncThunk('dashboaard/fetchTotalLoadedBags',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/loading-count/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

export const fetchTotalUnloadedBags = createAsyncThunk('dashboaard/fetchTotalUnloadedBags',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/unloading-count/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

export const fetchStockCount = createAsyncThunk('dashboaard/fetchStockCount',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/stock-count/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);


export const fetchTruckInfoByCnF = createAsyncThunk('dashboaard/fetchTruckInfoByCnF',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/truck-count-by-cnf/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

export const fetchArrivalDepartureAnalysis = createAsyncThunk('dashboaard/fetchArrivalDepartureAnalysis',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/arrival-departure/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

export const fetchLoadingUnloadingAnalysis = createAsyncThunk('dashboaard/fetchLoadingUnloadingAnalysis',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/loading-unloading-analysis/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);


export const fetchHandlingRate = createAsyncThunk('dashboaard/fetchHandlingRate',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/handling-rate-by-cnf/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

export const fetchHandlingRateAnalysis = createAsyncThunk('dashboaard/fetchHandlingRateAnalysis',async (queryParams,thunkAPI) => {
  try {
      const response = await makeRequestReduxApi("GET","/api/cement_count_automation/handling-rate-analysis/",queryParams);
      return response;
    } catch (error) {
      console.error('API request failed:', error);
      // if (error.response && (error.response.status === 404 || error.response.status === 401)) {
      //   localStorage.removeItem("Authorization");
      //   window.location.reload();
      // }
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    factoryNames: [],
    CnFNames: [],
    stockCount: [],
    truckInfoByCnf: [],
    arrivalDepartureAnalysis: [],
    loadingUnloadingAnalysis: [],
    handlingRate: [],
    handlingRateAnalysis: [],
    totalLoadedBags: null,
    totalUnloadedBags: null,
    loadedBagsFilterPeriod: null,
    unloadedBagsFilterPeriod: null,
    stateLocation: null,
    stockCountFilterPeriod: null,
    truckInfoByCnFFilterPeriod: null,
    arrivalDepartureAnalysisfilterPeriod: null,
    loadingUnloadingAnalysisfilterPeriod: null,
    handlingRatefilterPeriod: null,
    handlingRateAnalysisfilterPeriod: null,
    loading: false,
    error: null,
  },
  reducers: {
    setStateLocation: (state,action) => {
       state.stateLocation = action.payload;
    },
    setLoadedBagsfilterPeriod: (state,action) => {
       state.loadedBagsFilterPeriod = action.payload;
    },
    setUnloadedBagsfilterPeriod: (state,action) => {
       state.unloadedBagsFilterPeriod = action.payload;
    },
    setStockCountfilterPeriod: (state, action) => {
      state.stockCountFilterPeriod = action.payload;
    },
    setTruckInfoByCnFfilterPeriod: (state, action) => {
      state.truckInfoByCnFFilterPeriod = action.payload;
    },
    setArrivalDepartureAnalysisfilterPeriod: (state, action) => {
      state.arrivalDepartureAnalysisfilterPeriod = action.payload;
    },
    setloadingUnloadingAnalysisfilterPeriod: (state, action) => {
      state.loadingUnloadingAnalysisfilterPeriod = action.payload;
    },
    sethandlingRatefilterPeriod: (state, action) => {
      state.handlingRatefilterPeriod = action.payload;
    },
    sethandlingRateAnalysisfilterPeriod: (state, action) => {
      state.handlingRatefilterPeriod = action.payload;
    }
    


  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFactoryNames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFactoryNames.fulfilled, (state, action) => {
        state.loading = false;
        state.factoryNames = action.payload;
      })
      .addCase(fetchFactoryNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchHandlingRateAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHandlingRateAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.handlingRateAnalysis = action.payload;
      })
      .addCase(fetchHandlingRateAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchHandlingRate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHandlingRate.fulfilled, (state, action) => {
        state.loading = false;
        state.handlingRate = action.payload;
      })
      .addCase(fetchHandlingRate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchLoadingUnloadingAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoadingUnloadingAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingUnloadingAnalysis = action.payload;
      })
      .addCase(fetchLoadingUnloadingAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchArrivalDepartureAnalysis.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchArrivalDepartureAnalysis.fulfilled, (state, action) => {
        state.loading = false;
        state.arrivalDepartureAnalysis = action.payload;
      })
      .addCase(fetchArrivalDepartureAnalysis.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchStockCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStockCount.fulfilled, (state, action) => {
        state.loading = false;
        state.stockCount = action.payload;
        
      })
      .addCase(fetchStockCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchTruckInfoByCnF.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTruckInfoByCnF.fulfilled, (state, action) => {
        state.loading = false;
        state.truckInfoByCnf = action.payload;
        
      })
      .addCase(fetchTruckInfoByCnF.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCnFNames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCnFNames.fulfilled, (state, action) => {
        state.loading = false;
        state.CnFNames = action.payload;
        
      })
      .addCase(fetchCnFNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchTotalLoadedBags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTotalLoadedBags.fulfilled, (state, action) => {
        state.loading = false;
        state.totalLoadedBags = action.payload;
        
      })
      .addCase(fetchTotalLoadedBags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchTotalUnloadedBags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTotalUnloadedBags.fulfilled, (state, action) => {
        state.loading = false;
        state.totalUnloadedBags = action.payload;
        
      })
      .addCase(fetchTotalUnloadedBags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    
    
  },
});

export const {  sethandlingRateAnalysisfilterPeriod,
                sethandlingRatefilterPeriod,
                setloadingUnloadingAnalysisfilterPeriod,
                setTruckInfoByCnFfilterPeriod,
                setStateLocation,
                setLoadedBagsfilterPeriod,
                setUnloadedBagsfilterPeriod,
                setStockCountfilterPeriod,
                setArrivalDepartureAnalysisfilterPeriod } = dashboardSlice.actions;
export default dashboardSlice.reducer;


