import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  defs,
  linearGradient,
  stop
} from 'recharts';

// Function to format data
const dataFormatter = (data) => {
  console.log(data);
  if (data) {
    return data.map(item => {
      const company = item.cnf_name || 'Unknown Company'; // Default value if cnf_name is not defined
      let rate = 0;

      if (item.handling_rate) {
        rate = parseFloat(item.handling_rate.replace('%', '')); // Convert the rate to a number
      }

      return {
        name: company,
        HandlingRate: rate, // Use the numeric value for the chart
      };
    });
  }
  return []; // Return an empty array if data is not an array
};

// Custom Tooltip component
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="bg-white rounded-lg p-2 shadow-xl w-50 text-[1.5vh]">
        <div className="flex gap-2">
          {payload.map((item, index) => (
            <div key={index} className="mr-2">
              <p className="m-0 text-[1.25vh] text-teal-500 font-600">{data.name}</p>
              <p className="m-0 text-black text-[1.5vh] font-bold opacity-0.5 font-12">{`${item.value} `}</p>
              <p className={`m-0 text-[1.5vh] font-bold text-teal-500 ${item.fill}`}>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

const HandlingRateGraph = () => {
  const handlingRate_data = useSelector((state) => state.dashboard.handlingRate);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const formattedData = dataFormatter(handlingRate_data.handling_rates);
    setData(formattedData);
    console.log(formattedData)
  }, [handlingRate_data, dispatch]);

  return (
    <div className="w-full h-96 p-4 relative">
      <div className="absolute top-0 right-0 text-[#B4B4B4] font-600">
        <h3 className="text-[1.25vh]">The chart displays the top C&F companies, but is not limited to these.</h3>
      </div>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          data={data}
          margin={{
            top: 30, // Adjust to provide space for the text above
            right: 30,
            left: 5,
          }}
        >
          <defs>
            <linearGradient id="gradientColor" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor="#00ADB5" stopOpacity={1} />
              <stop offset="100%" stopColor="#004C4F" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} className="bg-gray-100" />
          <XAxis
            dataKey="name"
            tick={{ fill: 'black' }}
            className="text-[0.9vh] font-bold"
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', width: '100px' }}
            interval={0} // Ensure all X-axis labels are shown
            tickFormatter={(value, index) => (index % Math.ceil(data.length / 5) === 0 ? value : '')} // Show a maximum of 5 labels
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#00ADB5' }}
            className="text-[1.25vh] font-bold"
            label={{ value: 'Rate', angle: -90, position: 'insideLeft', fill: 'gray' }}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} // Y-axis from 0 to 100 with steps of 10
            tickFormatter={(value) => `${value}%`}
          />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
          <Bar dataKey="HandlingRate" barSize={20} fill="url(#gradientColor)" className="no-hover" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HandlingRateGraph;
