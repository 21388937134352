import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Menu } from '@headlessui/react';
import { RiArrowDropDownLine } from "react-icons/ri";
import { sethandlingRateAnalysisfilterPeriod, fetchHandlingRateAnalysis } from "../../feature/Dashboard/dashboardSlice";
import { useDispatch, useSelector } from 'react-redux';

const formatString = (str) => {
  return str.toLowerCase().replace(/ /g, "_");
};

const options = [
  "Last 12 Months",
  "Last 6 Weeks"
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white rounded-lg p-2 shadow-xl w-50 text-sm z-50">
        <div className="flex gap-2">
          {payload.map((item, index) => (
            <div key={index} className="mr-2">
              <p className="m-0 text-black text-base font-bold opacity-0.5 font-12">{`${item.value}% `}</p>
              <p className={`m-0 text-sm font-bold text-teal-500 ${item.fill}`}>
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

const HandlingRateAnalyzeGraph = ({ selectedOptions }) => {
  const colors = selectedOptions.map(option => option.color);
  const [selectedTimeRange, setSelectedTimeRange] = useState("Last 6 Weeks");
  const handlingRateAnalysis = useSelector((state) => state.dashboard.handlingRateAnalysis);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sethandlingRateAnalysisfilterPeriod(formatString(selectedTimeRange)));
  }, [selectedTimeRange, dispatch]);

  useEffect(() => {
    if (selectedOptions.length > 0) {
      let cnfNamesString = [];
      
      selectedOptions.forEach((option, index) => {
        // if (index > 0) {
        //   cnfNamesString += ",";
        // }
        cnfNamesString.push(option.label);
      });

      const queryDictionary = {
        cnf_names: cnfNamesString.toString(),
        period: formatString(selectedTimeRange)
      };

      dispatch(fetchHandlingRateAnalysis(queryDictionary));
    }
  }, [selectedOptions, selectedTimeRange, dispatch]);

  // Transform data
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (handlingRateAnalysis && handlingRateAnalysis.handling_rates) {
      const transformedData = handlingRateAnalysis.handling_rates.map(entry => {
        const dateToFormat = entry.month_start || entry.week_start;
        const isMonthData = entry.month_start !== undefined;
        
        let formattedDate;
        if (isMonthData) {
          // If it's monthly data
          formattedDate = new Date(dateToFormat).toLocaleString('default', { month: 'short', year: 'numeric' });
        } else {
          // If it's weekly data
          const date = new Date(dateToFormat);
          const monthName = date.toLocaleString('default', { month: 'short' });
          const weekNumber = getWeekOfMonth(date);
          formattedDate = `${monthName} - Week ${weekNumber}`;
        }

        const dataPoint = { name: formattedDate, date: new Date(dateToFormat) };

        Object.entries(entry.handling_rates).forEach(([company, rate]) => {
          dataPoint[company] = rate;
        });

        return dataPoint;
      });

      // Sort data based on the date
      const sortedData = transformedData.sort((a, b) => a.date - b.date);
      setChartData(sortedData);
    }
  }, [handlingRateAnalysis]);

  // Helper function to get week number within a month from a date
  const getWeekOfMonth = (date) => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const weekNumber = Math.ceil((date.getDate() + startOfMonth.getDay()) / 7);
    return weekNumber;
  };

  return (
    <div className="w-full h-96 p-4 relative">
      <div className="absolute top-2 text-[#B4B4B4] font-600 left-2">
        <Menu as="div" className={`relative z-20`}>
          <Menu.Button className="relative bg-teal-100 text-[rgba(18,101,104,1)] px-2 py-1 rounded-md flex items-center space-x-2 border-none focus:outline-none">
            Time Line: <span className="text-sm">{selectedTimeRange}</span>
            <RiArrowDropDownLine className="w-5 h-5" />
          </Menu.Button>
          <Menu.Items className="bg-teal-100 border border-gray-200 rounded-md mt-2 shadow-lg">
            {options.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div
                    className={`${
                      active ? 'bg-[#e5f7f8]' : ''
                    } text-[rgba(18,101,104,1)] px-4 py-2 cursor-pointer text-sm`}
                    onClick={() => setSelectedTimeRange(option)}
                  >
                    {option}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          data={chartData}
          margin={{
            top: 60,
            right: 30,
            left: 5, // Increased left margin
            bottom: 35 // Increased bottom margin
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            className="bg-gray-100"
          />
          <XAxis
            dataKey="name"
            tick={{ fill: "#00ADB5" }}
            className="text-xs font-bold"
            dx={10}
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', width: '500px' }}
            interval={0} // Ensure all X-axis ticks are shown
            // angle={-90} // Rotate labels for better readability
            textAnchor="end"
            tickMargin={10} // Added margin for ticks
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#00ADB5" }}
            className="text-xs font-bold"
            label={{
              value: "Rate",
              angle: -90,
              position: "insideLeft",
              fill: "gray",
            }}
            domain={['auto', 'auto']} // Automatically adjust Y-axis scale
            tickFormatter={(value) => `${value}%`}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          {selectedOptions.map((option, index) => (
            <Bar
              key={option.label}
              dataKey={option.label}
              barSize={10}
              fill={colors[index % colors.length]}
              name={option.label}
              className="no-hover"
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HandlingRateAnalyzeGraph;
