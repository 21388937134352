import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TruckLoadUnloadGraph from './TruckLoadUnloadGraph';
import { makeRequest } from '../../api/api'; // Import the API request utility

function TruckLoadUnloadData() {
  const selectedCompany = useSelector((state) => state.truck.selectedCompany);
  const selectedTruck = useSelector((state) => state.truck.selectedTruck);
  const { locationSelected } = useSelector((state) => state.navbar);


  const [data, setData] = useState([]);

  useEffect(() => {
    if (selectedCompany && selectedTruck) {
      const fetchData = async () => {
        try {
          const response = await makeRequest('GET', `/api/cement_count_automation/loading-unloading-count-by-month/?cnf_name=${selectedCompany}&truck_registration_number=${selectedTruck}&state=${locationSelected}`);
          const formattedData = response.monthly_data.map(item => ({
            name: item.month,
            Loaded: item.loading_sum,
            Unloaded: item.unloading_sum,
          }));
          setData(formattedData);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      };
      fetchData();
    }
  }, [selectedCompany, selectedTruck]);

  return (
    <div className="pt-4 pl-4 pr-4 bg-white rounded-lg w-full mx-auto flex flex-col items-start justify-between h-[50vh]">
      <div className="flex items-center justify-between w-full mb-4">
        <h1 className="text-16 font-semibold text-[#242325]">Loaded/Unloaded Data</h1>
      </div>
      <TruckLoadUnloadGraph data={data} />
    </div>
  );
}

export default TruckLoadUnloadData;
