import { Button, Dialog, DialogPanel, Description, DialogTitle } from '@headlessui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLocationSelected } from '../../feature/Navbar/NavbarSlice';
import LocationMap from '../../component/LocationMap';
import { FaMapMarkerAlt } from 'react-icons/fa';

function LocationWise({ isOpen, closeModal }) {
  const dispatch = useDispatch();
  const {locationSelected} = useSelector((state) => state.navbar);

  const handleMarkerClick = (stateName) => {
    dispatch(setLocationSelected(stateName));
  };

  return (
    <Dialog open={isOpen} as="div" className="relative z-30 focus:outline-none" onClose={closeModal}>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true"></div>
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className={`w-812 h-731 max-w-md rounded-3xl bg-white p-6 shadow-lg transition-all transform ${isOpen ? 'scale-125 opacity-100' : 'scale-95 opacity-0'}`}
          >
            <Button
              onClick={closeModal}
              className="absolute top-4 right-4 text-red-500 hover:text-red-700 focus:outline-none"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </Button>
            <DialogTitle as="h3" className="text-base/7 font-medium text-black">
              Select location from map
            </DialogTitle>
            <div className="mt-4 w-full h-auto" style={{ height: '300px' }}>
              <LocationMap onMarkerClick={handleMarkerClick} />
            </div>
            <Description className="mt-4 rounded-md bg-teal-100" style={{ backgroundColor: '#e5f7f9', padding: '0.5rem' }}>
              <span className="text-xs" style={{ color: '#4a4a4a' }}>Kindly use the cursor to select the states in order to view the statistics.</span>
              <br />
              <span className="text-teal-800 font-bold text-sm">{locationSelected}</span> {/* "|" this is required for color formatting purposes */}
              {/* <span className="text-teal-800 font-bold text-sm">42</span> */}
              {/* <span className="text-xs">C&F Companies</span> */}
            </Description>
            <div className="mt-4 flex justify-between">
              <div className='flex justify-center gap-2'>
              <div className="flex items-center">
                <div className="w-4 h-4 mr-2"><FaMapMarkerAlt color={"#FF4444"}/></div>
                <span className="text-sm">Factory</span>
              </div>
              <div className="flex items-center">
                <div className="w-4 h-4 mr-2"><FaMapMarkerAlt color={"#007BFF"}/></div>
                <span className="text-sm">C&F</span>
              </div>
              </div>
              <Button
                className="inline-flex items-center gap-2 rounded-md bg-teal-800 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                onClick={closeModal}
              >
                Apply
              </Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export default LocationWise;
