import React from "react";
import { useLocation } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import locationIcon from "../Assets/SVG/location-icon.svg";
import { useSelector } from "react-redux";

const Navbar = ({ title, subTitle, openModal }) => {
  const locationPath = useLocation().pathname;
  const { locationSelected } = useSelector((state) => state.navbar);
  // const { selectedLocation } = useSelector((state) => state.location);
  console.log(locationSelected);
  console.log("hksdbjhds");
  return (
    <div className="p-4 bg-white rounded-md mt-2 h-20">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold">{title}</h1>
          <p className="text-[#737373] text-xs">{subTitle}</p>
        </div>
        <div
          className="p-2 bg-[#E5F7F8] flex items-center rounded-md cursor-pointer"
          onClick={openModal}
        >
          <BiMap className="h-4 w-4 text-gray-700 mr-2" />
          <span className="text-[#126568] text-sm font-semibold">
            {locationSelected}
          </span>
          {/* {locationPath === '/home' ? (
            <>
            </>
          ) : (
            <> */}
          {/* <img src={locationIcon} alt="location icon" className="h-4 w-4 text-gray-700 mr-2" /> */}
          {/* <span className="text-[#126568] text-sm font-bold">Select on map</span> */}
          {/* </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
