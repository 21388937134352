import React, { useState, useEffect } from 'react';
import { FaCaretUp, FaCaretDown } from "react-icons/fa6";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@headlessui/react';
import { fetchCnFNames,fetchStockCount,setStockCountfilterPeriod } from '../../feature/Dashboard/dashboardSlice';


function TotalStocks() {
  const {locationSelected} = useSelector((state) => state.navbar);

  const formatString = (str) => {
    return str.toLowerCase().replace(/ /g, '_');
  };

  const [activeFilter, setActiveFilter] = useState('weekly');
  const [stockInCount, setStockInCount] = useState(0);
  const [stockOutCount, setStockOutCount] = useState(0);
  const [totalStockAvailable, setTotalStockAvailable] = useState(0);
  const filters = ['weekly', 'monthly'];
  const timeRanges = ['Till Today', 'Last 3 Months', 'Last 6 Months', 'Last 9 Months', 'Last 1 Year'];
  const [selectedCompany, setSelectedCompany] = useState("ALL C&F");
  const [selectedTimeRange, setSelectedTimeRange] = useState('Till Today');
  const stock_count = useSelector((state) => state.dashboard.stockCount)



  

    // .map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()); //capitalize first letter and turn every other letter in lower case for every word in given list
  const loading = useSelector((state) => state.dashboard.loading);
  const error = useSelector((state) => state.dashboard.error);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCnFNames({'state':locationSelected}));
  }, [dispatch, locationSelected]);

  const raw_cnf = useSelector((state) => state.dashboard.CnFNames)
  const companies = raw_cnf
  const stockCount = useSelector((state) => state.dashboard.stockCount)
  
  // const [unloadedBagsCount, setUnloadedBagsCount] = useState(0);

  useEffect(() => {
    dispatch(setStockCountfilterPeriod(formatString(activeFilter)));
  }, [activeFilter, dispatch]);

  useEffect(() => {
    if (selectedCompany !== 'ALL C&F') {
      dispatch(fetchStockCount({ "cnf_name":selectedCompany, "period":formatString(activeFilter) } ));
    }
    else {
      dispatch(fetchStockCount());
    }
  }, [selectedCompany, activeFilter, dispatch]);

  useEffect(() => {
    if (stock_count) {
      setStockInCount(stock_count.stock_in_count);
      setStockOutCount(stock_count.stock_out_count);
      setTotalStockAvailable(stock_count.total_stocks_available);
    
      
    }
  }, [stock_count, dispatch]);


  return (

    <div className='p-5 flex flex-col gap-y-2 bg-white flex-1 rounded-lg'>

      <div className='flex justify-between w-full'>
        <div className="text-lg">Total Stocks</div>
        <div>
          <Menu as="div" className="z-20 relative px-2 ">
            <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span className="text-sm">{selectedCompany}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </Menu.Button>
            <Menu.Items className="absolute mt-2 w-48 bg-white border border-[#BCD8DA] shadow-lg rounded-md right-1 max-h-[30vh] overflow-auto">
              {companies.map((company, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-[#e5f7f8]' : ''} px-4 py-2 cursor-pointer text-xs text-[#126568]`}
                      onClick={() => setSelectedCompany(company)}
                    >
                      {company}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>
      </div>

      <div className='font-bold text-[4vh] text-[#126568]'>{ totalStockAvailable }</div>

      <div className='flex flex-row justify-between w-full'>
        <div className="text-sm">Total Loaded Bags</div>
        <div>
          {/* <Menu as="div" className="relative bg-[#E5F7F8] px-2 border border-transparent rounded text-[#126568]">
            <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
              <span className="text-sm">{selectedTimeRange}</span>
              <RiArrowDropDownLine className="w-5 h-5" />
            </Menu.Button>
            <Menu.Items className="absolute mt-2 w-40 bg-white shadow-lg rounded-md py-1 right-1">
              {timeRanges.map((range, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-gray-100' : ''} px-4 py-2 cursor-pointer text-sm`}
                      onClick={() => setSelectedTimeRange(range)}
                    >
                      {range}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu> */}
        </div>
      </div>

      <div className='flex justify-between'>
        <div className='flex gap-4'>
          <div>
            <div className='flex justify-center items-center gap-1 text-[#2AC239] text-sm'>Stock In <FaCaretDown /></div>
            <div className='font-medium text-2xl text-[#242325]'>{ stockInCount }</div>
          </div>
          <div>
            <div className='flex justify-center items-center gap-1 text-[#F23B3B] text-sm'>Stock Out <FaCaretUp /></div>
            <div className='font-medium text-2xl text-[#242325]'>{ stockOutCount }</div>
          </div>
        </div>
        <div className='relative z-10 flex justify-center items-center'>
          {filters.map((filter) => (
            <div
              key={filter}
              onClick={() => setActiveFilter(filter)}
              className={`cursor-pointer px-2 py-1 border border-transparent rounded text-sm ${activeFilter === filter ? 'bg-[#126568] text-white' : 'text-[#126568]'}`}
            >
              {filter}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TotalStocks;
