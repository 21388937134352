import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocationFilter from "../component/LocationFilter";
import OwnerName from "../component/OwnerName";
import HandlingRate from "../component/HandlingRate";
import FullAddress from "../component/FullAddress";
import LoadedUnloadedData from "../container/Location/LoadedUnloadedData";
import TruckArrivalAnalysis from "../container/Location/TruckArrivalAnalysis";
import UnloadingAnalysis from "../container/Location/UnloadingAnalysis";
import LocationWise from "../container/Dashboard/LocationWise";
import {
  fetchAddressOwnerDetails,
  fetchHandlingRate,
  setSelectedLocation,
} from "../feature/Location/LocationSlice"; // Import the action

const LocationPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const dispatch = useDispatch();
  const {
    selectedLocation,
    selectedCompany,
    addressOwnerDetails,
    handlingRate,
  } = useSelector((state) => state.location);
  const { locationSelected } = useSelector((state) => state.navbar);

  useEffect(() => {
    dispatch(setSelectedLocation(locationSelected));
  }, [dispatch, locationSelected]);

  useEffect(() => {
    if (locationSelected && selectedCompany) {
      dispatch(
        fetchAddressOwnerDetails({
          cnf: selectedCompany,
          state: locationSelected,
        })
      );
    }
  }, [locationSelected, selectedCompany, dispatch]);

  useEffect(() => {
    if (locationSelected && selectedCompany) {
      dispatch(
        fetchHandlingRate({ cnf: selectedCompany, state: locationSelected })
      );
    }
  }, [locationSelected, selectedCompany, dispatch]);

  return (
    <div className="bg-[#F6F6F8]  overflow-y-scroll h-[85vh]  border-gray-200 rounded-md ">
      <div className="flex flex-col gap-5 p-1">
      <LocationFilter isLocationPage={true} />
      <div className="flex gap-5 mt-5">
        <div className="flex-1 flex flex-col gap-3">
          <div className="flex justify-between gap-3">
            <div className="flex-1 justify-between">
              <OwnerName
                type={"owner"}
                title={"Owner Name"}
                name={addressOwnerDetails?.owner_name || ""}
                id={addressOwnerDetails?.contact_info || ""}
              />
            </div>
            <div className="flex-1">
              <HandlingRate
                title={"Handling Rate"}
                value={handlingRate?.handling_rate || "N/A"} // Display directly from API
                // unit={"Sacks/Day"} // Uncomment if needed
              />
            </div>
          </div>
          <LoadedUnloadedData />
        </div>
        <div className="flex-1 flex flex-col gap-3">
          <FullAddress
            type={"location"}
            address={addressOwnerDetails?.cnf_address || ""}
          />
          <TruckArrivalAnalysis />
          <UnloadingAnalysis />
        </div>
      </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <LocationWise isOpen={isModalOpen} closeModal={closeModal} />
        </div>
      )}
    </div>
  );
};

export default LocationPage;
