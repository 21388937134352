import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { fetchTruckArrivalAnalysis } from "../../feature/Location/LocationSlice";

const TruckArrivalAnalysis = () => {
  const dispatch = useDispatch();
  const { locationSelected } = useSelector((state) => state.navbar);
  const { truckArrivalAnalysis, selectedCompany } = useSelector((state) => state.location);

  // Local state for duration
  const [localDuration, setLocalDuration] = useState("this_month");

  useEffect(() => {
    if (locationSelected && selectedCompany) {
      dispatch(
        fetchTruckArrivalAnalysis({
          cnf: selectedCompany,
          state: locationSelected,
          duration: localDuration,
        })
      );
    }
  }, [dispatch, locationSelected, selectedCompany, localDuration]);

  const handleDurationChange = (newDuration) => {
    setLocalDuration(newDuration); // Update local duration state
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white rounded-lg p-2 shadow-xl text-sm">
          <div className="flex gap-2">
            {payload.map((item, index) => (
              <div key={index} className="mr-2">
                <p className={`m-0 text-sm font-bold text-teal-500 ${item.fill}`}>
                  {item.name}
                </p>
                <p className="text-black text-base font-bold font-lg">{`${item.value} Trucks`}</p>
              </div>
            ))}
          </div>
          <p className="text-xs text-teal-500 font-semibold">{data.date}</p>
        </div>
      );
    }
    return null;
  };

  const transformedData = (truckArrivalAnalysis || []).map((item) => ({
    date: item.date,
    truck_count: item.truck_count,
  }));

  const durationLabels = {
    this_week: "Current Week",
    this_month: "Current Month",
  };

  return (
    <div className="pl-3 pr-2 pt-3 pb-1 bg-white rounded-lg">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-bold text-black">Truck Arrival Analysis</h1>
        <Menu as="div" className="relative">
          <Menu.Button className="flex items-center bg-[#e5f7f8] text-[#126568] py-2 px-4 rounded-lg focus:outline-none">
            {durationLabels[localDuration]}
            <ChevronDownIcon className="w-5 h-5 ml-2 text-[#126568]" aria-hidden="true" />
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="p-1">
              {Object.entries(durationLabels).map(([value, label]) => (
                <Menu.Item key={value}>
                  {({ active }) => (
                    <button
                      onClick={() => handleDurationChange(value)}
                      className={`${
                        active ? "bg-blue-100 text-blue-900" : "text-gray-900"
                      } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      {label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      </div>
      <div className="mt-1 h-40">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={transformedData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="10%" stopColor="#126568" stopOpacity={1} />
                <stop offset="100%" stopColor="#126568" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              className="bg-gray-100"
            />
            <XAxis
              dataKey="date"
              tick={{ fill: "#00ADB5", fontSize: "10px", fontWeight: "800" }}
              tickLine={false}
              axisLine={{ strokeDasharray: "3 3" }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#00ADB5", fontSize: "10px", fontWeight: "800" }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area dataKey="truck_count" fill="url(#colorUv)" stroke="none" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TruckArrivalAnalysis;
