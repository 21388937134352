import React, { useState, useEffect } from 'react';
import { RiArrowDropDownLine } from "react-icons/ri";
import { Menu } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMonthlyTruckData } from '../../feature/Trucks/TruckSlice';

function MonthlyTruckData() {
    const dispatch = useDispatch();
    const monthlyData = useSelector((state) => state.truck.monthlyData);
    const selectedCompany = useSelector((state) => state.truck.selectedCompany);
    const selectedTruck = useSelector((state) => state.truck.selectedTruck);


    // const timeRanges = [
    //     { label: "This Month", value: "this_month" },
    //     { label: "Last 3 Months", value: "last_3_months" },
    //     { label: "Last 6 Months", value: "last_6_months" },
    //     { label: "Last 1 Year", value: "last_1_year" }
    // ];

    const { locationSelected } = useSelector((state) => state.navbar);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Number of items per page

    useEffect(() => {
        if (selectedCompany && selectedTruck) {
            dispatch(fetchMonthlyTruckData({
                state: locationSelected,
                truckRegistrationNumber: selectedTruck,
                cnfName: selectedCompany,
                // period: selectedTimeRange
            }));
        }
    }, [selectedCompany, selectedTruck,  dispatch, locationSelected]);

    const totalPages = Math.ceil((monthlyData?.length || 0) / itemsPerPage);
    
    const paginatedData = monthlyData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    // const selectedRangeLabel = timeRanges.find(range => range.value === selectedTimeRange)?.label || "Select Time Range";
       console.log (monthlyData)
    return (
        <div className="p-4 bg-white rounded-md m-2 w-100">
            <div className='flex justify-between mb-4'>
                <h1 className='text-16 text-[#242325] font-semibold'>Truck Data</h1>
                <div>
                    {/* <Menu as="div" className="relative bg-[#E5F7F8] px-2 border border-transparent rounded text-[#126568]">
                        <Menu.Button className="flex items-center space-x-2 bg-transparent border-none focus:outline-none">
                            <span>{selectedRangeLabel}</span>
                            <RiArrowDropDownLine className="w-6 h-6" />
                        </Menu.Button>
                        <Menu.Items className="absolute mt-2 w-48 bg-white shadow-lg rounded-md py-1 right-1">
                            {timeRanges.map((range, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <div
                                            className={`${active ? 'bg-gray-100' : ''} px-4 py-2 cursor-pointer`}
                                            onClick={() => setSelectedTimeRange(range.value)}
                                        >
                                            {range.label}
                                        </div>
                                    )}
                                </Menu.Item>
                            ))}
                        </Menu.Items>
                    </Menu> */}
                </div>
            </div>
            <div className="overflow-x-auto rounded-md">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            {/* <th className="text-center py-2 px-4 border-b border-gray-200 text-[#126568]">Month</th> */}
                            <th className="text-center py-2 px-4 border-b border-gray-200 text-[#126568]">Loaded Bags</th>
                            <th className="text-center py-2 px-4 border-b border-gray-200 text-[#126568]">Outbound Time</th>
                            <th className="text-center py-2 px-4 border-b border-gray-200 text-[#126568]">Receiving Point</th>
                            <th className="text-center py-2 px-4 border-b border-gray-200 text-[#126568]">Receiving Time</th>
                            <th className="text-center py-2 px-4 border-b border-gray-200 text-[#126568]">Unloaded Bags</th>
                            <th className="text-center py-2 px-4 border-b border-gray-200 text-[#126568]">Raise Concern</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
                            paginatedData.map((row, index) => (
                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#F2FEFF]"}>
                                    {/* <td className="text-center py-2 px-4 border-b border-gray-200">{row.month}</td> */}
                                    <td className="text-center py-2 px-4 border-b border-gray-200">{row.loading_bags}</td>
                                    <td className="text-center py-2 px-4 border-b border-gray-200">{new Date(row.outbound_time).toLocaleString()}</td>
                                    <td className="text-center py-2 px-4 border-b border-gray-200">{row.receiving_point || 'N/A'}</td>
                                    <td className="text-center py-2 px-4 border-b border-gray-200">{new Date(row.receiving_time).toLocaleString() || 'N/A'}</td>
                                    <td className="text-center py-2 px-4 border-b border-gray-200">{row.unloaded_bags}</td>
                                    <td className="text-center py-2 px-4 border-b border-gray-200">
                                        {row.concern === 'Raised' ? (
                                            <span className="font-semibold text-[#242325]">Raised, <span className="font-semibold text-[#F23B3B] cursor-pointer">Revoke</span></span>
                                        ) : (
                                            <span className="font-semibold cursor-pointer">Raise</span>
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center py-4 text-gray-500">
                                    No data available for the selected time range.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between mt-4">
                <button 
                    onClick={() => handlePageChange(currentPage - 1)} 
                    disabled={currentPage === 1} 
                    className="px-8 py-1 text-black border rounded-md hover:bg-[#e5f7f8] transition-colors text-base"
                >
                    Previous
                </button>
                <span className="text-gray-700 content-center">
                    Page {currentPage} of {totalPages}
                </span>
                <button 
                    onClick={() => handlePageChange(currentPage + 1)} 
                    disabled={currentPage === totalPages} 
                    className="px-8 py-1 bg-[#126568] text-white border border-[#126568] rounded-md hover:bg-[#0f4c51] transition-colors text-base"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default MonthlyTruckData;
