import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { BiChevronDown, BiMap } from "react-icons/bi";
import {
  fetchLocations,
  fetchCompanies,
  setSelectedCompany,
  resetFilters,
} from "../feature/Location/LocationSlice";

const LocationFilter = ({ isLocationPage }) => {
  const dispatch = useDispatch();
  const { companies, selectedCompany, status } = useSelector(
    (state) => state.location
  );
  const { locationSelected } = useSelector((state) => state.navbar);
  console.log(locationSelected);
  // useEffect(() => {
  //   if (status === "idle") {
  //     dispatch(
  //       fetchLocations({ company: selectedCompany, state: locationSelected })
  //     );
  //   }
  // }, [dispatch, status, selectedCompany, locationSelected]);
  useEffect(() => {
    dispatch(fetchCompanies({ location: locationSelected }));
  }, [locationSelected, dispatch]);
  useEffect(() => {
    console.log(locationSelected);
    dispatch(fetchCompanies({ location: locationSelected }));
  }, []);
  const handleCompanyChange = (company) => {
    dispatch(setSelectedCompany(company));
  };

  const handleReset = () => {
    if (isLocationPage) {
      dispatch(resetFilters());
    }
  };

  return (
    <div className="p-5 bg-white rounded-md">
      <h1 className="text-sm text-[#737373] font-semibold mb-3">
        Location-Filter
      </h1>

      {/* Location Dropdown (Commented out)
      <Menu as="div" className="relative">
        <Menu.Button className="p-2 bg-[#E5F7F8] flex items-center justify-between rounded-md mb-4 mt-2 w-full">
          <div className="flex items-center">
            <BiMap className="h-5 w-5 mr-1" style={{ color: '#126568' }} />
            <span className="text-[#126568] text-sm font-semibold">{selectedLocation || 'Select Location'}</span>
          </div>
          <div className="flex items-center">
            <BiChevronDown className="h-5 w-5" style={{ color: '#126568' }} />
          </div>
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 w-full bg-white border border-[#BCD8DA] rounded-md shadow-lg z-10">
          {locations.length > 0 ? (
            locations.map(location => (
              <Menu.Item key={location}>
                {({ active }) => (
                  <div
                    className={`w-full text-left px-4 py-2 text-xs text-[#126568] ${active ? 'bg-gray-100' : ''}`}
                    style={{ color: 'rgb(18 101 104 / var(--tw-text-opacity))' }}
                    onClick={() => handleLocationChange(location)}
                  >
                    {location}
                  </div>
                )}
              </Menu.Item>
            ))
          ) : (
            <div className="p-2 text-gray-500">No locations available</div>
          )}
        </Menu.Items>
      </Menu> 
      */}

      <div className="flex items-center text-xs mb-4">
        <p className="text-[#737373] mr-1">Select C&F Companies in</p>
        <span className="text-[#126568] font-semibold">{locationSelected}</span>
      </div>

      {/* CNF Dropdown */}
      <Menu as="div" className="relative">
        <Menu.Button className="p-2 bg-white border-2 border-[#BCD8DA] flex items-center justify-between rounded-md mb-3 w-full">
          <div className="flex items-center">
            <span className="text-[#126568] text-xs font-semibold">
              {selectedCompany || "Select Company"}
            </span>
          </div>
          <div className="flex items-center">
            <BiChevronDown className="h-5 w-5" style={{ color: "#126568" }} />
          </div>
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 w-full bg-white border border-[#BCD8DA] rounded-md shadow-lg z-10">
          {companies.length > 0 ? (
            companies.map((company) => (
              <Menu.Item key={company}>
                {({ active }) => (
                  <div
                    className={`w-full text-left px-4 py-2 text-xs text-[#126568] ${
                      active ? "bg-gray-100" : ""
                    }`}
                    style={{
                      color: "rgb(18 101 104 / var(--tw-text-opacity))",
                    }}
                    onClick={() => handleCompanyChange(company)}
                  >
                    {company}
                  </div>
                )}
              </Menu.Item>
            ))
          ) : (
            <div className="p-2 text-gray-500">No companies available</div>
          )}
        </Menu.Items>
      </Menu>

      {/* <div className="flex justify-end space-x-3">
        <button
          className="px-3 py-1 text-black border rounded-md hover:bg-[#e5f7f8] transition-colors text-md"
          onClick={handleReset}
        >
          Reset
        </button>
        <button className="px-3 py-1 bg-[#126568] text-white border border-[#126568] rounded-md hover:bg-[#0f4c51] transition-colors text-md">
          Apply
        </button>
      </div> */}
    </div>
  );
};

export default LocationFilter;
