import React, { useState } from 'react';
import TruckFilters from '../container/TruckInfo/TruckFilters';
import TruckLoadUnloadData from '../container/TruckInfo/TruckLoadUnloadData';
import TruckRateAnalysis from '../container/TruckInfo/TruckRateAnalysis';
import MonthlyTruckData from '../container/TruckInfo/MonthlyTruckData';
import LocationWise from '../container/Dashboard/LocationWise';

function TruckInfoPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="bg-[#F6F6F8]  border-gray-200 rounded-md flex flex-col overflow-y-scroll h-[85vh]">
    <div className="flex flex-col gap-2 p-1">
      <div className="flex flex-col gap-2  w-full">
      <TruckFilters />
          
          <div className='flex gap-5 w-full'>
         
            <div className='w-1/2'><TruckLoadUnloadData /></div>
            <div className='w-1/2'><TruckRateAnalysis /></div>
          </div>
          </div>
          <div>
            <MonthlyTruckData />
          </div>
        
        </div>
      
      <div className={`flex ${isModalOpen ? 'blur-md' : ''} transition-all duration-300 ease-in-out`}>
        <LocationWise isOpen={isModalOpen} closeModal={closeModal} />
      </div>
    </div>
  );
}

export default TruckInfoPage;
